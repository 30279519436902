import Vue from "vue";
import Router from "vue-router";
import signin from "@/components/signin";
import admin from "@/components/admin";
import adminV2 from "@/components/adminV2";

import welcomePage from "@/components/welcomePage";
import startGame from "@/components/startGame";
import play from "@/components/play";

import doItYourselfPage from "@/components/doItYourselfPage";

import tutorialV2 from "@/components/tutorialV2";
import mainPage from "@/components/mainPage";
import demoPage from "@/components/demoPage";
import guideDigital from "@/components/guideDigital";

import escaladeKids from "@/components/escaladeKids";
import resultsByPoints from "@/components/resultsByPoints";
import tutoPage from "@/components/tutoPage";

import lake from "@/components/lake";
import geneve from "@/components/geneve";
import carouge from "@/components/carouge";
import goodVibesGE from "@/components/goodVibesGE";
import nations from "@/components/nations";
import darkGeneva from "@/components/darkGeneva";
import escalade from "@/components/escalade";
import lancy from "@/components/lancy";

import josephB50 from "@/components/josephB50";
import paymentPage from "@/components/paymentPage";
import rousseauVoltaireGeneve from "@/components/rousseauVoltaireGeneve";
import digitalGuideMainPage from "@/components/digitalGuideMainPage";

import singleWord from "@/components/singleWord";
// import mainPage from '@/components/mainPage'

import results from "@/components/results";

Vue.use(Router);

export default new Router({
  mode: "history",

  routes: [
    {
      path: "/singleWord",
      name: "singleWord",
      component: singleWord,
    },
    {
      path: "/adminRafa",
      name: "adminRafa",
      component: admin,
    },
    {
      path: "/adminV2",
      name: "adminV2",
      component: adminV2,
    },
    {
      path: "/josephB50",
      name: "josephB50",
      component: josephB50,
    },
    {
      path: "/play",
      name: "play",
      component: play,
    },

    {
      path: "/tutoPage",
      name: "tutoPage",
      component: tutoPage,
    },
    {
      path: "/resultsByPoints",
      name: "resultsByPoints",
      component: resultsByPoints,
    },
    {
      path: "/escaladeKids",
      name: "escaladeKids",
      component: escaladeKids,
    },

    {
      path: "/digitalGuideMainPage",
      name: "digitalGuideMainPage",
      component: digitalGuideMainPage,
    },
    {
      path: "/rousseauVoltaireGeneve",
      name: "rousseauVoltaireGeneve",
      component: rousseauVoltaireGeneve,
    },
    {
      path: "/paymentPage",
      name: "paymentPage",
      component: paymentPage,
    },
    {
      path: "/startGame",
      name: "startGame",
      component: startGame,
    },
    {
      path: "/doItYourselfPage",
      name: "doItYourselfPage",
      component: doItYourselfPage,
    },
    {
      path: "/carouge",
      name: "carouge",
      component: carouge,
    },
    {
      path: "/darkGeneva",
      name: "darkGeneva",
      component: darkGeneva,
    },
    {
      path: "/results",
      name: "results",
      component: results,
    },
    {
      path: "/signin",
      name: "signin",
      component: signin,
    },
    {
      path: "/lancy",
      name: "lancy",
      component: lancy,
    },
    {
      path: "/goodVibesGE",
      name: "goodVibesGE",
      component: goodVibesGE,
    },
    {
      path: "/guideDigital",
      name: "guideDigital",
      component: guideDigital,
    },
    {
      path: "/demoPage",
      name: "demoPage",
      component: demoPage,
    },
    {
      path: "/geneve",
      name: "geneve",
      component: geneve,
    },
    {
      path: "/tutorialV2",
      name: "tutorialV2",
      component: tutorialV2,
    },
    {
      path: "/nations",
      name: "nations",
      component: nations,
    },
    {
      path: "/lake",
      name: "lake",
      component: lake,
    },
    {
      path: "/escalade",
      name: "escalade",
      component: escalade,
    },
    {
      path: "/",
      name: "mainPage",
      component: mainPage,
    },
    {
      path: "/welcomePage",
      name: "welcomePage",
      component: welcomePage,
    },
  ],
});
