<template >
  <div
    style="
      display: block;
      margin: 0px;
      padding: 0px;
      min-height: calc(100vh - 150px);
      margin-bottom: 55px;
    "
    class="mt-4 container d-flex"
  >
    <div class="row p-0 m-0" style="max-width: 590px">
      <!-- ********************FULL SCREEN MESSAGES********************************************** -->
      <div
        v-if="showFiveSecCountDown"
        id="fiveSecCountDown"
        class="fullScreenMessages"
      ></div>

      <audio
        v-if="soundCountDown"
        id="audio"
        controls
        autoplay
        style="display: none"
      >
        <source src="../../static/sounds/5sec.mp3" type="audio/mp3" />
      </audio>
      <!-- ******************** END OF FULL SCREEN MESSAGES********************************************** -->
      <div
        class="sentenceAboveMap mb-3"
        id="sentenceAboveMap"
        :style="variableBackgroundColor"
        v-if="!showVideo"
      >
        <div style="font-family: 'system-ui'">
          {{ lang }}{{ sentenceShownAboveMap }}
        </div>
      </div>

      <!-- ******************** MAP AND VIDEO ********************************************** -->

      <div
        style="
          min-height: 320px;
          background: black;
          max-width: 590px;
          position: relative;
          overflow: hidden;
        "
      >
        <div id="map2" :style="variableBackgroundColor">
          <!-- <map /> -->
          <GmapMap
            v-if="showMap"
            :center="centerMap"
            :zoom="Number(mapParameters.zoom)"
            map-type-id="satellite"
            :tilt="0"
            style="width: 100vw; height: 50vh"
            :styles="myMapStyle"
            :options="{
              zoomControl: false,
              mapTypeControl: true,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: true,
              fullscreenControl: false,
              disableDefaultUi: false,
            }"
          >
            <PolylineMap
              v-if="noGoogleRouteArray[pointNum]"
              :path="wayPoints[pointNum]"
              :editable="false"
              :options="{
                strokeColor: '#5C81C4',
                strokeWeight: 5,
                strokeOpacity: 0.8,
              }"
            />
            <DirectionsRenderer
              v-else
              travelMode="WALKING"
              :origin="startLocation"
              :destination="endLocation"
              :waypoints="wayPoints[pointNum]"
              :icon="iconImage"
            />

            <GmapMarker
              v-if="showPosMarker"
              :clickable="true"
              @click="centerMap = m.position"
              :position="myCurrentPosition"
              :icon="iconImage"
            />
            <GmapMarker
              style="margin-right: 10px !important"
              :key="index"
              v-for="(m, index) in twoMarkersArray"
              :position="m"
              :clickable="true"
              :draggable="false"
              :label="{
                text: `${index + 1}`,
                color: 'white',
                fontSize: '9px',
              }"
              @click="centerMap = m"
            />
          </GmapMap>
        </div>
        <!-- <div v-else id="videoDiv">
         
          <youtube
            v-if="withVideo"
            :video-id="videoId"
            :player-vars="playerVars"
            @playing="playing"
            style="position: relative; width: 100vw"
          ></youtube>
          <div v-else class="pointInfoDiv">
            {{ translation.didYouKnow }}

            <br />
            <br />
            {{ points[pointNum + 1].pointInformation }}
          </div>
        </div> -->
        <!-- J'ESSAZE DE METTRE LES FULLSCREENMESSAGE SUR LA CARTE -->
        <div>
          <div
            v-if="showMessageTimeFinished"
            id="timeFinishedElement"
            class="messageAboveMap p-3"
            style="font-size: 40px"
          >
            {{ translation.timeOver }}
          </div>

          <div
            v-if="showVerifyingPosition"
            id="verifyingPosition"
            class="messageAboveMap p-3"
            style="font-size: 30px"
          >
            {{ translation.locationVerification }}
            <img src="../../static/img/satellite.gif" alt="" />
          </div>
          <div v-if="showBuzzer" id="buzzer" class="messageAboveMap">
            <button
              id="buttonBuzzer"
              class="buzzerButton btn btn-primary btn-lg font-weight-bold mb-4"
              style="z-index: 20"
              @click="buzzerClicked"
            ></button>
          </div>
        </div>
      </div>
      <!-- ******************** END OF MAP AND VIDEO ********************************************** -->

      <!-- <div v-if="showtextEncouragement" class="textEncouragement">
          <span class="appearSlowly">{{ textEncouragement }}</span>
        </div> -->

      <!-- **************************** BUTTON UNDER MAP AND VIDEO IF NOT AT DESTINATION YET************************************** -->

      <div
        class="row align-items-center justify-content-center align-self-center m-0 p-0"
        style="
          min-height: 15%;
          width: 100vw;
          margin-bottom: 50px !important;
          height: 50px;
          top: -145px;
          position: relative;
        "
      >
        <div
          v-if="!reachedThePoint && showTimerBackground"
          class="checkLocationButtonDisabeld rtl text-center mt-4"
        >
          <div
            class="timerBackground"
            @click="flipToBuzzer"
            v-if="showTimerBackground"
          >
            <div id="timer" class="text-center">0{{ timePerPoint }}:00</div>
          </div>

          <!-- **************************** SHOW EXPLOSIONS ************************************** -->

          <div id="explosionDiv" v-if="showExplosion">
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
            <div class="explosion"></div>
          </div>

          <!-- **************************** END OF  SHOW EXPLOSIONS ************************************** -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { gmapApi } from "vue2-google-maps";
// import map from "./map";

import DirectionsRenderer from "../DirectionsRenderer";
import PolylineMap from "../polyline";

export default {
  components: { DirectionsRenderer, PolylineMap },

  // computed: {
  //   google: gmapApi,
  // },
  data() {
    return {
      showTimerBackground: true,
      // lang: this.$parent.language,
      mapParameters: this.$parent.mapParameters,
      sentenceShownAboveMap: "",
      sentenceAboveMap: "",
      wongAnswerSound: require("../../static/sounds/wrongAnswer.mp3"),
      buzzerFlip: require("../../static/sounds/correctAnwer.mp3"),
      typingSound: require("../../static/sounds/typing.mp3"),
      buzzerClickSound: require("../../static/sounds/buzzerClicked.mp3"),
      pointsScoreCoins: require("../../static/sounds/coins.mp3"),
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      countDown: "",
      countDounTimeInSeconds: "",
      timeFinishedLoop: "",
      showMessageTimeFinished: false,
      showBuzzer: false,
      showVerifyingPosition: false,
      showFiveSecCountDown: false,
      soundCountDown: false,
      startTimeCountDown: null,
      showVideo: false,
      showExplosion: false,
      pointsScore: 0,
      min: "",
      sec: 0,
      showTimmer: true,
      playerVars: {
        autoplay: 1,
      },
      showMap: true,
      noGoogleRouteArray: [],
      gettingClosed: false,
      showtextEncouragement: true,
      textEncouragement: "",
      textEncouragementArray: [
        "אתם בדרך הנכונה",
        "הכפתור יופיע כשתגיעו למקום",
        "תמשיכו ללכת לפי המסלול",
      ],
      textEncouragementArrayClose: [
        "אתם קרובים !",
        "עוד מעט מגיעים",
        "מעולים אתם !",
        "עוד מעט תראו את הכפתור",
        "יופי של קצב",
      ],
      buttonNameNotThere: "",
      reachedThePoint: false,
      rangeInPosition: 0.0002,
      buttonName: "בדרך",
      backgroundButttonColor: "white",
      currentPlace: null,
      places: [],
      startLocation: null,
      endLocation: null,
      // alertSkipPoint: false,
      imageUrl: "",
      showPosMarker: false,
      showButtonInfoScreen: false,
      iconImage: {
        url: require("../../static/img/circle.png"),
      },
      watchPositionId: null,
      position: { lat: null, lng: null },
      showCurrentPositionButton: true,
      myCurrentPosition: "",
      myMapStyle: {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      firstQuestion: 1,
      showPointInformation: false,
      secondsToTime: "00:00:00",
      gameSecondToTime: "",
      totalSeconds: 0,
      startTime: "",
      endTime: "",
      showLastDialog: false,
      key: "",
      questiomMultipleChoices: true,
      rowPhotoMissingWord: true,
      dialogWrongAnswer: false,
      dialogCorrectAnswer: false,
      loading: false,
      src: "",
      timeNow: "",
      questionNumber: -1,
      errorNumber: 0,
      pointNum: this.$parent.pointNum,
      pointNumForEvolution: 1,
      letter: "",
      allLetters: "",
      gameTimeInSecond: "",
      userKey: "",
      translationMissingWord: "Mot manquant sur l'image",
      translation: "",

      activeClass: "activePoint",
      notActive: "smallPoint",
      activeClassPoint: "activeClassPoint",
      notActivePoint: "numbersInCercle",
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
    if (this.$parent.showLastDialog != true) {
      this.startFiveSecCountDown();
      setTimeout(() => {
        this.countSeconds();
        if (localStorage.getItem("startTime")) {
          this.startTime = localStorage.getItem("startTime");
        } else {
          this.startTime = Date.now();
        }
      }, 6000);
    }

    this.showCurrentPosition();
    this.checkNoGoogleRoute();
    if (this.gameParameters) {
      console.log("this.gameParameters exist ok");
    } else {
      console.log("bug");
      this.reopenGame();
    }
    this.userKey = localStorage.getItem("userKey");
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    setTimeout(() => {
      this.addMarker();
    }, 1000);
  },
  computed: {
    google: gmapApi,

    withVideo() {
      if (localStorage.getItem("withVideo")) {
        return localStorage.getItem("withVideo");
      } else {
        localStorage.setItem(
          "withVideo",
          this.points[this.pointNum + 1].withVideo
        );
        return this.points[this.pointNum + 1].withVideo;
      }
    },
    videoId() {
      if (localStorage.getItem("videoUrl")) {
        return localStorage.getItem("videoUrl");
      } else {
        localStorage.setItem(
          "videoUrl",
          this.points[this.pointNum + 1].videoUrl
        );
        return this.points[this.pointNum + 1].videoUrl;
      }
    },
    timePerPoint() {
      // if (localStorage.getItem("timePerPoint")) {
      //   return localStorage.getItem("timePerPoint");
      // } else {
      localStorage.setItem(
        "timePerPoint",
        this.points[this.pointNum + 1].timePerPoint
      );
      return this.points[this.pointNum + 1].timePerPoint;
      // }
      // return this.points[this.pointNum + 1].timePerPoint;
      // return 1;
    },
    twoMarkersArray() {
      if (this.pointNum == 0) {
        var oneMarkerArrray = [
          {
            lat: this.markers[this.pointNum].position.lat,
            lng: this.markers[this.pointNum].position.lng,
          },
        ];
        return oneMarkerArrray;
      } else {
        var twoMarkersArray = [
          {
            lat: Number(this.markers[this.pointNum - 1].position.lat),
            lng: Number(this.markers[this.pointNum - 1].position.lng),
          },
          {
            lat: Number(this.markers[this.pointNum].position.lat),
            lng: Number(this.markers[this.pointNum].position.lng),
          },
        ];
        return twoMarkersArray;
      }
    },
    centerMap() {
      var centeredMap;
      if (this.pointNum == 0) {
        centeredMap = {
          lat: Number(this.markers[this.pointNum].position.lat),
          lng: Number(this.markers[this.pointNum].position.lng),
        };
      } else {
        centeredMap = {
          lat:
            (Number(this.markers[this.pointNum - 1].position.lat) +
              Number(this.markers[this.pointNum].position.lat)) /
            2,
          lng:
            (Number(this.markers[this.pointNum - 1].position.lng) +
              Number(this.markers[this.pointNum].position.lng)) /
            2,
        };
      }
      return centeredMap;
    },
    variableBackgroundColor() {
      return {
        "--buttonColor": this.backgroundButttonColor,
      };
    },
    newInformationPointString() {
      return this.points[
        this.pointsArrayOrder[this.pointNum]
      ].pointInformation.replaceAll("\n", "\n");
    },
    audioSrc() {
      if (
        Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
          .audioUrl
      ) {
        return Object.values(this.points)[
          this.pointsArrayOrder[this.pointNum] - 1
        ].audioUrl;
      } else {
        return null;
      }
    },
    markers() {
      var markers;
      if (localStorage.getItem("markers")) {
        markers = JSON.parse(localStorage.getItem("markers"));
        // console.log("markers", markers);
      } else {
        markers = this.$store.getters.markers;
        // console.log("markers", markers);
      }
      // var markers = this.$store.getters.markers;
      var markersArray = [];
      markers.forEach((marker) => {
        if (marker != null) {
          markersArray.push({ position: { lat: marker.lat, lng: marker.lng } });
        }
      });
      // console.log("markersArray", markersArray);

      return markersArray;
    },
    wayPoints() {
      var wayPoints;
      if (localStorage.getItem("wayPoints")) {
        wayPoints = JSON.parse(localStorage.getItem("wayPoints"));
        console.log("wayPoints", wayPoints);
      } else {
        wayPoints = this.$store.getters.wayPoints;
        console.log("wayPoints", wayPoints);
      }
      var wayPointsArray = [];
      wayPoints.forEach((wayPoint) => {
        if (wayPoint != null) {
          wayPointsArray.push(wayPoint);
        }
      });
      console.log("wayPointsArray", wayPointsArray);
      return wayPointsArray;
    },

    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },

    gameParameters() {
      return this.$store.getters.gameParameters;
    },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        return this.$store.getters.pointsArrayOrder;
      }
    },
    points() {
      return this.$store.getters.points;
    },
  },

  methods: {
    showSentenceAboveMap() {
      this.sentenceShownAboveMap = "";
      var arrayOfLetters = "";
      arrayOfLetters = JSON.stringify(this.sentenceAboveMap).split("");
      // console.log("arrayOfLetters", arrayOfLetters);
      let index = 0;
      var audio = new Audio(this.typingSound);
      audio.play();
      this.loopTextAboveMap = setInterval(() => {
        if (this.sentenceShownAboveMap.length < arrayOfLetters.length - 2) {
          if (arrayOfLetters[index] != '"') {
            this.sentenceShownAboveMap =
              this.sentenceShownAboveMap + arrayOfLetters[index];
          }
          index++;
        } else {
          arrayOfLetters = "";
          this.sentenceAboveMap == "";
          index = 0;
          clearInterval(this.loopTextAboveMap);
          audio.pause();
        }
      }, 150);
    },
    flipToBuzzer() {
      this.showBuzzer = true;
      setTimeout(() => {
        if (document.getElementById("buttonBuzzer").hasAttribute("disabled")) {
          console.log("hasAttribute");
          document
            .getElementById("buttonBuzzer")
            .removeAttribute("disabled", "");
        } else {
          console.log("Does not hasAttribute");
        }
      }, 10);
      clearInterval(this.countDown);
      // I RESET THE TIMEOVER MESSAGE AND THEN ONLY STOP THE LOOP
      this.showMessageTimeFinished = false;
      clearInterval(this.timeFinishedLoop);
      clearInterval(this.loopTextAboveMap);
      this.sentenceShownAboveMap = "";
      this.sentenceAboveMap = "";
      this.sentenceAboveMap = this.translation.clickTheBuzzer;
      //this.sentenceAboveMap = "Appuyer sur le buzzer!";
      this.showSentenceAboveMap();
      var audio = new Audio(this.buzzerFlip);
      audio.play();
    },
    buzzerClicked() {
      clearInterval(this.loopTextAboveMap);
      clearInterval(this.countDown);
      this.sentenceShownAboveMap = "";
      this.sentenceAboveMap = "";
      setTimeout(() => {
        clearInterval(this.countDown);
        document.getElementById("buttonBuzzer").setAttribute("disabled", "");
        console.log("this.countDounTimeInSeconds", this.countDounTimeInSeconds);
        if (this.countDounTimeInSeconds > 1) {
          this.addScorePoints();
        } else {
          //this.showVideo = true;
          // localStorage.setItem("showVideo", this.showVideo);
          this.reachedThePoint = false;
          // document.getElementById("g-contrast").classList.add("hide");
          this.showBuzzer = false;
          this.showExplosion = false;
          this.countDounTimeInSeconds = "";
          this.showTimerBackground = false;
          localStorage.removeItem("startTimeCountDown");
          this.goToNextStep();
        }
      }, 1000);
    },
    startFiveSecCountDown() {
      this.showFiveSecCountDown = true;
      this.runCheckPosition();
      var seconds = 6;
      var countFiveSec = setInterval(() => {
        if (seconds < 1) {
          if (this.$parent.pointNum == 0) {
            //console.log("lang", this.lang);
            this.sentenceAboveMap = this.translation.goToFirstPoint;
          } else {
            //  this.sentenceAboveMap = this.translation.followThePath;
            this.sentenceAboveMap = this.translation.followThePath;
          }
          this.showSentenceAboveMap();

          clearInterval(countFiveSec);
          this.countDownSeconds();
          this.showFiveSecCountDown = false;
          this.soundCountDown = false;
        } else {
          this.soundCountDown = true;
          seconds--;
          document.getElementById("fiveSecCountDown").innerHTML = seconds;
        }
      }, 1000);
    },
    countSeconds() {
      setInterval(() => {
        this.timeNow = Date.now();
        this.totalSeconds = Math.floor((this.timeNow - this.startTime) / 1000);
        var pad = function (num, size) {
            return ("000" + num).slice(size * -1);
          },
          time = parseFloat(this.totalSeconds).toFixed(3),
          hours = Math.floor(time / 60 / 60),
          minutes = Math.floor(time / 60) % 60,
          seconds = Math.floor(time - minutes * 60);

        this.secondsToTime =
          pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
      }, 1000);
    },
    countDownSeconds() {
      this.showFiveSecCountDown = false;
      // console.log("start countDownSeconds");
      if (localStorage.getItem("startTimeCountDown")) {
        this.startTimeCountDown = JSON.parse(
          localStorage.getItem("startTimeCountDown")
        );
      } else {
        this.startTimeCountDown = Date.now();
        localStorage.setItem(
          "startTimeCountDown",
          JSON.stringify(this.startTimeCountDown)
        );
      }

      // this.min = this.timePerPoint;
      this.countDown = setInterval(() => {
        this.countDounTimeInSeconds = Math.floor(
          (this.startTimeCountDown +
            this.timePerPoint * 60 * 1000 -
            Date.now()) /
            1000
        );
        var time = parseFloat(this.countDounTimeInSeconds).toFixed(3);
        var minutes = Math.floor(time / 60) % 60;
        var seconds = Math.floor(time - minutes * 60);
        //****************************ONLY FOR TIME FINISHED */
        if (this.min == 0 && this.countDounTimeInSeconds < 1) {
          // this.backgroundButttonColor = "#3F9583";
          clearInterval(this.countDown);
          document.getElementById("timer").innerHTML = "00:00";
          this.timeFinished();
        }
        if (this.reachedThePoint == true && this.countDounTimeInSeconds > 1) {
          // this.backgroundButttonColor = "#3F9583";
          clearInterval(this.countDown);
        }
        if (seconds > 9) {
          document.getElementById("timer").innerHTML =
            "0" + minutes + ":" + seconds;
        } else {
          document.getElementById("timer").innerHTML =
            "0" + minutes + ":" + "0" + seconds;
        }
      }, 1000);
    },
    timeFinished() {
      this.sentenceAboveMap = this.translation.timeFinished;
      this.showSentenceAboveMap();
      this.timeFinishedLoop = setInterval(() => {
        this.showMessageTimeFinished = !this.showMessageTimeFinished;
      }, 5000);
    },
    goToNextStep() {
      this.$parent.showCorrectAnswerIcon = true;
      var audioCorrectAnswerSound = new Audio(this.correctAnswerSound);
      audioCorrectAnswerSound.play();
      this.$parent.checkElementPicked();
      localStorage.setItem("errorNumber", this.$parent.errorNumber);
      setTimeout(() => {
        this.$parent.showPointInformation = true;
        localStorage.setItem("showPointInformation", this.showPointInformation);
        this.$parent.showCorrectAnswerIcon = false;
        this.$parent.showButtonInfoScreen = true;
        this.$parent.showPickedItem = false;
        localStorage.setItem("showPickedItem", this.showPickedItem);
        this.$parent.showMainScreen = false;
        localStorage.setItem("showMainScreen", this.showMainScreen);
      }, 3000);
    },
    addScorePoints() {
      // var totalPoints = this.countDounTimeInSeconds * 60 + this.sec;
      var pointIndex = 0;
      // var totalPoints = this.countDounTimeInSeconds;
      //***************************************** */
      var totalPoints = parseFloat(this.countDounTimeInSeconds).toFixed(3);
      var minutes = Math.floor(totalPoints / 60) % 60;
      var seconds = Math.floor(totalPoints - minutes * 60);
      /********************************************************** countTimeToZero + addScorePoints*/
      var audio = new Audio(this.pointsScoreCoins);
      audio.play();
      var countTimeToZero = setInterval(() => {
        seconds--;
        //this.showExplosion = true;
        this.pointsScore++;
        pointIndex++;
        if (Number.isInteger(pointIndex / 3)) {
          // console.log("pointIndex", pointIndex);
          this.$parent.errorNumber = this.$parent.errorNumber + 1;
        }
        if (minutes > 0 && seconds == 0) {
          if (seconds > 9) {
            document.getElementById("timer").innerHTML =
              "0" + minutes + ":" + seconds;
          } else {
            document.getElementById("timer").innerHTML =
              "0" + minutes + ":" + "0" + seconds;
          }
          minutes--;
          seconds = 60;
        } else {
          // if (minutes == 0) {
          if (minutes == 0 && seconds == 0) {
            document.getElementById("timer").innerHTML = "00:00";
            setTimeout(() => {
              this.showExplosion = false;
              this.countDounTimeInSeconds = "";
              this.reachedThePoint = false;
              this.showTimerBackground = false;
              this.$parent.showStarUp(Math.ceil(pointIndex / 3));

              setTimeout(() => {
                // this.showVideo = true;
                //  document.getElementById("g-contrast").classList.add("hide");
                //  localStorage.setItem("showVideo", this.showVideo);
                this.showBuzzer = false;
                audio.pause();
                audio.currentTime = 0;
                localStorage.setItem("pointsScore", this.pointsScore);
              }, 3000);
              localStorage.removeItem("startTimeCountDown");
              var audio = new Audio(this.buzzerClickSound);
              audio.play();
              this.goToNextStep();
            }, 1000);
            this.reachedThePoint = false;
            clearInterval(countTimeToZero);
          } else {
            if (seconds > 9) {
              document.getElementById("timer").innerHTML =
                "0" + minutes + ":" + seconds;
            } else {
              document.getElementById("timer").innerHTML =
                "0" + minutes + ":" + "0" + seconds;
            }
          }
          // }
        }
      }, 20);
    },
    playing() {
      console.log("o/ we are watching!!!");
    },
    showMapTrue() {
      this.showMap = true;
    },
    checkNoGoogleRoute() {
      var noGoogleRouteArray = [];
      this.points.forEach((point) => {
        if (point.noGoogleRoute == true) {
          noGoogleRouteArray.push(true);
        } else {
          noGoogleRouteArray.push(false);
        }
      });

      this.noGoogleRouteArray = noGoogleRouteArray;
      // console.log("this.noGoogleRouteArray", this.noGoogleRouteArray);
    },
    reopenGame() {
      if (localStorage.getItem("navigationName")) {
        console.log("[reopenGame] ");
        if (localStorage.getItem("pointNum")) {
          this.$router.push("/" + localStorage.getItem("navigationName"));
          location.reload();
        } else {
          this.$router.push("/" + localStorage.getItem("navigationName"));
          localStorage.clear();
          console.log("[reopenGame] NO POINTNUM");
          location.reload();
        }
      } else {
        console.log("[reopenGame] NO navigationName");
        localStorage.clear();
        this.$router.push("/");
        location.reload();
      }
    },
    runCheckPosition() {
      // setInterval(() => {
      //   this.showtextEncouragement = !this.showtextEncouragement;
      //   if (this.gettingClosed) {
      //     this.textEncouragement =
      //       this.textEncouragementArrayClose[
      //         Math.floor(
      //           Math.random() * this.textEncouragementArrayClose.length
      //         )
      //       ];
      //   } else {
      //     this.textEncouragement =
      //       this.textEncouragementArray[
      //         Math.floor(Math.random() * this.textEncouragementArray.length)
      //       ];
      //   }
      // }, 5000);
      var checkPosition = setInterval(() => {
        let markerLat = this.markers[this.pointNum].position.lat;
        let markerLng = this.markers[this.pointNum].position.lng;
        // console.log("[checkPosition] B4 pos verrification");
        if (
          markerLat > this.myCurrentPosition.lat - this.rangeInPosition &&
          markerLat < this.myCurrentPosition.lat + this.rangeInPosition &&
          markerLng > this.myCurrentPosition.lng - this.rangeInPosition &&
          markerLng < this.myCurrentPosition.lng + this.rangeInPosition
        ) {
          // console.log("[checkPosition] in the range");

          this.backgroundButttonColor = "white";
          this.buttonName = "הגענו!";
          this.showMessageTimeFinished = false;
          clearInterval(this.timeFinishedLoop);
          this.verifyPosition();
          clearInterval(checkPosition);
        } else {
          if (
            markerLat > this.myCurrentPosition.lat - 0.001 &&
            markerLat < this.myCurrentPosition.lat + 0.001 &&
            markerLng > this.myCurrentPosition.lng - 0.001 &&
            markerLng < this.myCurrentPosition.lng + 0.001
          ) {
            // console.log("[checkPosition] ALMOST in the range");

            this.backgroundButttonColor = "white";
            this.gettingClosed = true;
            this.buttonName = "מתקרבים!";
            this.reachedThePoint = false;
          } else {
            // console.log(
            //   "[checkPosition] NOT in the range markerLat markerLng",
            //   markerLng,
            //   markerLat
            // );

            this.backgroundButttonColor = "white";
            this.buttonName = "בדרך";
            this.reachedThePoint = false;
          }
        }
      }, 3000);
    },

    verifyPosition() {
      let index = 0;
      this.showVerifyingPosition = true;
      var checkPosition = setInterval(() => {
        let markerLat = this.markers[this.pointNum].position.lat;
        let markerLng = this.markers[this.pointNum].position.lng;
        if (
          markerLat > this.myCurrentPosition.lat - this.rangeInPosition &&
          markerLat < this.myCurrentPosition.lat + this.rangeInPosition &&
          markerLng > this.myCurrentPosition.lng - this.rangeInPosition &&
          markerLng < this.myCurrentPosition.lng + this.rangeInPosition
        ) {
          this.backgroundButttonColor = "green";
          this.buttonName = "הגענו!";
          index++;
          if (index > 3) {
            // this.reachedThePoint = true;
            this.flipToBuzzer();
            // this.showBuzzer = true;
            clearInterval(this.timeFinishedLoop);

            this.timeFinishedLoop = "";
            clearInterval(checkPosition);
            this.showVerifyingPosition = false;
          }
          //
        } else {
          clearInterval(checkPosition);
          this.backgroundButttonColor = "#3F9583";
          this.showVerifyingPosition = false;
          this.runCheckPosition();
        }
      }, 1000);
    },

    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.pointNum == 0) {
        const marker1 = {
          lat: this.markers[0].position.lat,
          lng: this.markers[0].position.lng,
        };
        const marker2 = {
          lat: this.markers[0].position.lat,
          lng: this.markers[0].position.lng,
        };
        console.log("marker1 - marker2", marker1, marker2);
        this.startLocation = marker1;
        this.endLocation = marker2;
        console.log(
          "startLocation - endLocation",
          this.startLocation,
          this.endLocation
        );
      } else {
        const marker1 = {
          lat: this.markers[this.pointNum - 1].position.lat,
          lng: this.markers[this.pointNum - 1].position.lng,
        };
        const marker2 = {
          lat: this.markers[this.pointNum].position.lat,
          lng: this.markers[this.pointNum].position.lng,
        };
        this.startLocation = marker1;
        this.endLocation = marker2;
        console.log("marker1 - marker2 NOT FIRST POINT", marker1, marker2);
        console.log(
          "startLocation - endLocation",
          this.startLocation,
          this.endLocation
        );
      }
    },
    showCurrentPosition() {
      if (navigator.geolocation) {
        console.log("navigator.geolocation OK");
        this.showCurrentPositionButton = false;
        this.watchPositionId = navigator.geolocation.watchPosition(
          (position) => {
            let pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            // console.log("position", position);
            this.myCurrentPosition = pos;
            // console.log("this.myCurrentPosition", this.myCurrentPosition);
            this.showPosMarker = true;
          }
          // () => {
          //   handleLocationError(true, infoWindow, map.getCenter());
          // },
          // { enableHighAccuracy: true }
        );
      } else {
        // Browser doesn't support Geolocation
        alert("Browser doesn't support Geolocation");
        // handleLocationError(false, infoWindow, map.getCenter());
      }
    },

    translate(lang) {
      switch (lang) {
        case "fr":
          this.translationMissingWord = "Missing word on the picture";
          this.translation = {
            timeFinished: "Le temps s'est écoulé",

            goToFirstPoint: "Rendez-vous au premier point",
            followThePath: "Suivez le parcours sur la carte",
            clickTheBuzzer: "Appuyez sur le buzzer !",

            back: "Fermer",
            skipPoint: "Passer au point suivant",
            skipPointBeware:
              "Attention! Si vous ne trouvez pas la pancarte, vous pouvez passer au point suivant, 10 minutes serons ajoutées à votre temps final.",
            mistakes: "Mistakes",
            runningTime: "Elapsed time",
            map: "Map",
            pointNumber: "On the map, find the nº",
            goThere: "Go there, and find this inscription ",
            didYouKnow: "Did you know?",
            goToQuestions: "Go to questions!",
            wellDone: "Well done!",
            correctAnswer: "The answer is correct!",
            continue: "Let’s continue…",
            wrongAnswer: "That's not the correct answer.",
            tryAgain: "Let's try again!",
            visitOf: "We hope you enjoyed your visit of",
            yourResults: "Your results are:",
            errorNumber: "Mistakes:",
            totalTime: "Total time:",
            goToRanking:
              "Before you leave, you can go take a look at the results and see where you are!",
            checkLocation: "הגעתי !",
            evolution: "התקדמות",
            timeOver:
              "Votre temps s'est écoulé, rendez-vous au point indiqué sur la carte",
            locationVerification: "בדיקת מיקום",
          };

          // code block
          break;
        case "he":
          this.translationMissingWord = "מילה חסרה בתמונה";
          this.translation = {
            timeFinished: "Le temps s'est écoulé",

            goToFirstPoint: "Rendez-vous au premier point",
            followThePath: "Suivez le parcours sur la carte",
            clickTheBuzzer: "לחצו על הבאזר",
            back: "לסגור",
            skipPoint: "לעבור לנקודה הבאה",
            skipPointBeware:
              "זהירות! אם אתם לא מוצאים את השלט, אתם יכולים לעבור לנקודה הבאה, 10 דקות יתווספו לזמן הסופי שלכם.",
            mistakes: "טעויות",
            runningTime: "זמן רץ",
            pointsScore: "נקודות",
            map: "מפה",
            pointNumber: "מצאו במפה את תחנה מספר",
            goThere: "לחצו על הכפתור כשהגעתם לנקודה",
            didYouKnow: "האם ידעתם?",
            goToQuestions: "המשך",
            wellDone: "יפה מאוד!",
            correctAnswer: "הגעתם למקום הנכון",
            continue: "לחצו להמשך",
            wrongAnswer: "תשובה לא נכונה.",
            tryAgain: "ננסה שוב!",
            visitOf: "מקווים שנהנתם מהמשחק",
            yourResults: "התוצאות שלכם:",
            errorNumber: "טעויות:",
            totalTime: "סה''כ זמן:",
            goToRanking: "לפני שאתם מסיימים, אתם יכולים לראות באיזה מיקום אתם!",
            checkLocation: "הגעתי !",
            evolution: "התקדמות",
            timeOver:
              "Votre temps s'est écoulé, rendez-vous au point indiqué sur la carte",
            locationVerification: "בדיקת מיקום",
          };
          // code block
          break;
        default:
        // code block
      }
    },

    showResults() {
      // console.log("showResults");
      // localStorage.clear();
      this.$router.push("/resultsByPoints");
      // this.$store.dispatch("getResults", this.gameParameters.navigationName);
    },
    checkLocation() {
      console.log(
        "this.markers[this.pointNum].position.lat",
        this.markers[this.pointNum].position.lat
      );
      this.dialogCorrectAnswer = true;

      // let markerLat = this.markers[this.pointNum].position.lat;
      // let markerLng = this.markers[this.pointNum].position.lng;

      // if (navigator.geolocation) {
      //   this.watchPositionId = navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       let pos = {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude,
      //       };
      //       this.myCurrentPosition = pos;
      //       console.log("position", position);
      //       console.log("this.myCurrentPosition", this.myCurrentPosition);
      //       if (
      //         markerLat > this.myCurrentPosition.lat - this.rangeInPosition &&
      //         markerLat < this.myCurrentPosition.lat + this.rangeInPosition &&
      //         markerLng > this.myCurrentPosition.lng - this.rangeInPosition &&
      //         markerLng < this.myCurrentPosition.lng + this.rangeInPosition
      //       ) {
      //         console.log("in the good range");
      //         this.dialogCorrectAnswer = true;
      //       } else {
      //         console.log("NOT in the good range");
      //         this.dialogWrongAnswer = true;
      //       }
      //     },
      //     () => {
      //       handleLocationError(true, infoWindow, map.getCenter());
      //     },
      //     { enableHighAccuracy: true }
      //   );
      // } else {
      //   // Browser doesn't support Geolocation
      //   alert("Browser doesn't support Geolocation");
      //   // handleLocationError(false, infoWindow, map.getCenter());
      // }
    },
  },
};
</script>

<style scoped>
.continueButton {
  width: 200px !important;
  left: 50px;
  top: -32px;
}
.continueButton.active {
  top: -26px !important;
}

.pointInfoDiv {
  color: gray;
  width: 100vw;
  height: 100%;
  background: white;
  min-height: 370px;
  padding: 20px;
  border: 4px solid #51c9b0;
  direction: rtl;
  text-align: right;
}

.hide {
  display: none;
}
.flip-card {
  background-color: transparent;
  border-radius: 50%;
  bottom: 37px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipCardTransfom {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border: 1px solid #999999;
  background-color: #cce3ca !important;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  border-radius: 50%;
  border: blue solid 2px;
  height: 100px;
  width: 100px;
  bottom: -12px;
}
.timerBackground {
  position: fixed;
  bottom: 36px;
  z-index: 3;
  background-color: white;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: solid 1px #0070b0;
  /* position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  /* backface-visibility: hidden;
  border: 1px solid #999999;
  background-color: #cce3ca !important;
  border-radius: 50%;
  border: yellow solid 2px;
  height: 100px;
  width: 100px;
  bottom: -20px; */
}
/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}
.bg-red {
  background: #ff7b7d !important;
  box-shadow: 0 0 0 1px #b6494d inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 3px 0 0 #b6494d, 0 8px 8px 1px rgb(0 0 0 / 50%) !important;
}
.bg-green {
  background: #99bd39 !important;
  box-shadow: 0 0 0 1px #687f27 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 3px 0 0 #687f27, 0 8px 8px 1px rgb(0 0 0 / 50%) !important;
}
.buzzerBottom {
  height: 50px;
  width: 30px;
  background: black;
}
.walkingGif {
  height: 101px;
  bottom: 5px;
  left: 1px;
  position: relative;
  /* border: 2px solid blue; */

  /* -webkit-transform-style: preserve-3d;
  animation: spin 2s linear infinite;
  transform-style: preserve-3d; */
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
    transform-style: preserve-3d;
  }
  100% {
    transform: rotateY(360deg);
    transform-style: preserve-3d;
  }
}
.buzzerButton {
  position: relative;
  border: 0;
  -webkit-transition: all 80ms linear;
  transition: all 80ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
  background: radial-gradient(circle at 42px 36px, white, red);
  height: 179px;
  width: 180px;
  border-radius: 100px;
  top: -68px;
  left: 2px;
}
.buzzerButton:active:focus,
.buzzerButton:focus:hover,
.buzzerButton:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.buzzerButton:active,
.buzzerButton.active {
  top: -30px;
}

.buzzerButton.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 20px 0 0 #4d5bbe, 0 40px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 40px 8px 1px rgba(0, 0, 0, 0.5);
  background-color: #447c70;
}

.buzzerButton.btn-primary:active,
.buzzerButton.btn-primary.active {
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 3px 0px 0px rgba(0, 0, 0, 0.3), 0 60px 8px 1px rgba(0, 0, 0, 0.5);
  background-color: #447c70;
}

.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 8px 0 0 #447c70, 0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}

#buzzer {
  animation-name: appear;
  animation-duration: 0.5s;
}

@keyframes appear {
  0% {
    top: 500px;
  }
}

#buzzer img {
  position: absolute;
  bottom: calc(50vh - 180px);
  left: calc(50vw - 180px);
  border-radius: 230px;
  -webkit-animation: scaling 0.7s infinite alternate;
}
@-webkit-keyframes scaling {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
  }
}
#videoDiv {
  margin-bottom: -5px;
}

.fullScreenMessages {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  color: white;
  text-align: center;
  padding-top: 27vh;
  font-size: 188px;
  font-family: "system-ui";
  -webkit-text-stroke: 5px #3369bc;
}
.messageAboveMap {
  position: absolute;
  top: 4px;
  left: 0px;
  height: 50vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  color: white;
  text-align: center;
  /* padding-top: 40%; */
  font-size: 188px;
  font-family: "system-ui";
  /* -webkit-text-stroke: 1px red; */
  border-radius: 15px;
}

#buzzer.messageAboveMap {
  background: rgba(248, 247, 247, 0.7);
  padding-top: 12vh;
}

#timer {
  background: transparent;
  color: #605e5e;
  font-size: 25px;
  padding-top: 23px;
  font-weight: bold;
}

.textEncouragement {
  font-family: system-ui;
  color: chartreuse;
  position: absolute;
  top: 40vh;
  font-size: 40px;
  /* -webkit-text-stroke: 1px red;  width and color */
  text-align: center;
}

@keyframes blinker {
  40% {
    opacity: 90%;
  }
  50% {
    opacity: 50%;
  }
  60% {
    opacity: 90%;
  }
}

.sentenceAboveMap {
  direction: ltr;
  text-align: center;
  /* width: 100%; */
  font-size: 23px;
  font-weight: bold;
  color: var(--buttonColor);
  border-right: 2px solid white;
  display: block;
  margin: 0 auto;
  height: 28px;
  padding-right: 4px;
  -webkit-animation: blink 0.8s infinite;
  animation: blink 0.8s infinite;
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: orangered;
  }
}
.buttonStyle {
  left: 15px;
  padding-bottom: 15px;
  position: relative;
  bottom: 28px;
}

.buttonStyleAlmostThere {
  color: white;
  position: relative;
  font-weight: bold;
  font-size: 35px;
  background-color: orangered;
  padding: 10px;
  border-radius: 17px;
  animation: glowing 1300ms infinite;
}
.checkLocationButtonDisabeld {
  font-size: 20px;
  padding-top: 35px;
  height: 80px;
  width: 80px;
  font-weight: bold;
  color: white;
  border-radius: 100px;
  /* background-color: var(--buttonColor); */
  /* -webkit-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite; */
  /* border: 1px solid #999999;
  background-color: #cce3ca !important; */
  color: #6666666b;
}
.checkLocationButton {
  font-size: 20px;
  padding-top: 35px;
  height: 100px;
  width: 100px;
  font-weight: bold;
  background-color: #9ceb95 !important;
  color: #1817176b;
  border-radius: 100px;
  /* background-color: var(--buttonColor); */
  -webkit-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}
@keyframes glowing {
  0% {
    /* background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805; */
    background-color: var(--buttonColor);
    box-shadow: 0 0 5px var(--buttonColor);
  }
  50% {
    /* background-color: #49e819;
    box-shadow: 0 0 20px #49e819; */
    background-color: var(--buttonColor);
    box-shadow: 0 0 25px var(--buttonColor);
  }
  100% {
    /* background-color: #2ba805;
    box-shadow: 0 0 5px #2ba805; */
    background-color: var(--buttonColor);
    box-shadow: 0 0 5px var(--buttonColor);
  }
}
.toQuestionButton {
  border: 1px solid #999999;
  background-color: #cce3ca !important;
  color: #605e5e;
  font-size: 20px;
  font-weight: bold;
  top: -38px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: relative;
}
.mainButton {
  border-radius: 50px;
  height: 56px;
  color: white !important;
  display: block;
  margin: 0px auto;
  height: 90px !important;
  width: 90px;
  border: solid darkgreen 2px;
}
.currentPosButton {
  position: absolute;
  top: 61px;
  font-size: 19px;
  z-index: 100;
  color: green;
  left: 11px;
  direction: rtl;
  font-family: system-ui;
  background: white;
  border: 1px solid green;
  padding: 7px 8px 0px;
  border-radius: 5px;
}
#erreurs {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 28px);
}
#evolution {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 35px);
}
#runningTime {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 13px);
}
#carte {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 6px);
}

.center {
  display: block;
  margin: 0px auto;
}
#mapButton {
  width: 30px;
  position: absolute;
  top: 4px;
  right: -10px;
  margin-right: calc(50vw - 155px);
  font-size: 25px;
  color: white;
  /* border: solid 1px black; */
  width: 42px;
  height: 40px;
  padding: 5px;
  /* background: white; */
  z-index: 5;
}
#map {
  position: fixed;
  top: -100vh;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: hidden;
  transition: all 1s;
}
#map2 {
  right: 0;
  width: 100vw;
  max-width: 600px;

  overflow: hidden;
  /* transition: all 1s; */
  border: solid 4px;
  border-color: var(--buttonColor);
  border-radius: 16px;
  background: transparent;
  /* animation: hueRotate 10s infinite linear; */
}
.showMap {
  position: fixed;
  top: 0 !important;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: hidden !important;
  transition: all 1s;
}

#imgUrl {
  display: block;
  margin: 0 auto;
}
.errorsTimeMap {
  background: rgba(255, 255, 255, 1);
  border: none;
  font-size: 20px;
  padding-top: 5px;
}
.errorsTimeMap > svg {
  position: relative;
  bottom: 3px;
}
.pointNumber {
  margin: 0 auto;
  border: #d41a1b solid 2px;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  font-size: 36px;
  padding-right: 1px;
  padding-top: 4px;
}
.rtl {
  direction: rtl;
}
.verticalAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80%;
}
.myModal {
  min-height: 200px;
  overflow: auto;
  width: 300px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  color: white;
}
.modalInformationPoint {
  box-shadow: none;
  border: solid rgb(9, 133, 235) 3px;
  margin-top: 1vh;
  width: 98vw;
  font-size: 22px;
  height: 98vh;
  overflow: auto;
  background: rgb(20, 186, 236);
  color: white;
}
.red {
  background-color: #d41a1b;
  border: solid white 2px;
}
.green {
  background-color: #0aa78d;
  border: 4px #eef307 solid;
}
.over {
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
.colorBtn {
  border-radius: 36px;
  min-height: 56px;
  color: white;
}
.fullWidth {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
}
.question {
  text-align: center;
  font-family: "Assistant", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: black;
}
.btnAnswer {
  margin: 0 auto;
  width: 320px;
  height: 60px;
  border-radius: 35px;
  font-size: 20px;
  color: white;
  background: rgb(64, 150, 83) !important;
}
.title {
  font-size: 25px;
  text-align: right;
  color: black;
}
.userName {
  font-size: 18px;
  text-align: left;
  color: black;
}
.time {
  letter-spacing: 0.05em;
  font-size: 24px;
  font-family: "Cabin", sans-serif;
  text-align: center;
  color: rgb(14, 15, 14, 0.8);
}
.startTime {
  letter-spacing: 0.05em;
  font-size: 40px;
  padding: 5px 0;
  font-family: "Inconsolata", monospace;
  color: #ffffff;
  text-align: center;
  position: relative;
  left: 50%;
  margin-top: 50px;
  top: -24px;
  transform: translate(-50%, -50%);
  color: #daf6ff;
}
.hebrewText {
  text-align: right;
  font-size: 15px;
  padding-right: 5px;
  color: black;
}

.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.fadeInVideo {
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;
}

.confetti {
  width: 15px;
  height: 15px;
  background-color: #b1f70f;
  position: absolute;
  left: 50%;
  animation: confetti 5s ease-in-out -2s infinite;
  transform-origin: left top;
}
.confetti:nth-child(1) {
  background-color: #faf623;
  left: 10%;
  animation-delay: 0;
}
.confetti:nth-child(2) {
  background-color: #129dee;
  left: 20%;
  animation-delay: -5s;
}
.confetti:nth-child(3) {
  background-color: #e21f0d;
  left: 30%;
  animation-delay: -3s;
}
.confetti:nth-child(4) {
  background-color: #f1dc6f;
  left: 40%;
  animation-delay: -2.5s;
}
.confetti:nth-child(5) {
  background-color: #039cf5;
  left: 50%;
  animation-delay: -4s;
}
.confetti:nth-child(6) {
  background-color: #f52916;
  left: 60%;
  animation-delay: -6s;
}
.confetti:nth-child(7) {
  background-color: #5cf24e;
  left: 70%;
  animation-delay: -1.5s;
}
.confetti:nth-child(8) {
  background-color: #d717e9;
  left: 80%;
  animation-delay: -2s;
}
.confetti:nth-child(9) {
  background-color: #f30e34;
  left: 90%;
  animation-delay: -3.5s;
}
.confetti:nth-child(10) {
  background-color: #7cf24e;
  left: 100%;
  animation-delay: -2.5s;
}
.confetti:nth-child(11) {
  background-color: #f52916;
  left: 15%;
  animation-delay: -6.5s;
}
.confetti:nth-child(12) {
  background-color: #5cf24e;
  left: 25%;
  animation-delay: -1.8s;
}
.confetti:nth-child(13) {
  background-color: #d717e9;
  left: 75%;
  animation-delay: -2.5s;
}
.confetti:nth-child(14) {
  background-color: #f30e34;
  left: 85%;
  animation-delay: -3.8s;
}
.confetti:nth-child(15) {
  background-color: #7cf24e;
  left: 55%;
  animation-delay: -4.2s;
}
.confetti:nth-child(16) {
  background-color: #d717e9;
  left: 75%;
  animation-delay: -3.5s;
}
.confetti:nth-child(18) {
  background-color: #f30e34;
  left: 85%;
  animation-delay: -6.8s;
}
.confetti:nth-child(17) {
  background-color: #7cf24e;
  left: 55%;
  animation-delay: -4.9s;
}

@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }
  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(7vw, 60vh);
  }
  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-25vw, 80vh);
  }
  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(20vw, 110vh);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@font-face {
  font-family: noot;
  src: url(../../static/fonts/Noot/noot-aj.woff);
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--buttonColor);
  color: var(--buttonColor);
  box-shadow: 9999px 0 0 -5px var(--buttonColor);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--buttonColor);
  color: var(--buttonColor);
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px var(--buttonColor);
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px var(--buttonColor);
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px var(--buttonColor);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--buttonColor);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--buttonColor);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--buttonColor);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--buttonColor);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--buttonColor);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px var(--buttonColor);
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--buttonColor);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--buttonColor);
  }
}

.g-container {
  position: relative;
  width: 300px;
  height: 170px;
  margin: auto;
}

/* .g-contrast {
  filter: contrast(10) hue-rotate(0);
  width: 300px;
  height: 300px;
  background-color: transparent;
  overflow: hidden;
  animation: hueRotate 10s infinite linear;
} */

.g-circle {
  position: relative;
  width: 300px;
  height: 250px;
  box-sizing: border-box;
  /* filter: blur(1px); */
}

.g-circle::after {
  content: "";
  position: relative;
  display: block;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 120px;
  height: 120px;
  background-color: var(--buttonColor);
  /* filter: contrast(10) hue-rotate(0); */
  border-radius: 42% 38% 62% 49% / 45%;
  animation: rotate 10s infinite linear;
}

.g-bubbles {
  position: relative;
  left: 50%;
  top: -1px;
  width: 47px;
  height: 13px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-radius: 0px 0px 179px 193px;
  background-color: var(--buttonColor);
  /* -webkit-filter: blur(3px);
  filter: blur(1px); */
}
#explosionDiv {
  position: absolute;
  left: 47%;
  top: 69px;
  width: 0px;
  height: 0px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background-color: rgb(89, 84, 176);
  z-index: 3;
}
.explosion {
  border-radius: 50%;
  background-color: rgb(89, 84, 176);
  position: absolute;
  transform: translate(-50%, -50%);
  animation: explosionAnimation 1s ease-out -infinite;
  z-index: 50;
}
.explosion:nth-child(1) {
  width: 8x;
  height: 8x;
  left: 10px;
  animation-delay: -3s;
  animation: explosionAnimation 0.5s ease-out infinite;
}
.explosion:nth-child(2) {
  width: 9px;
  height: 9px;
  left: 18px;
  animation-delay: -4s;
  animation: explosionAnimation 1s ease-out infinite;
}
.explosion:nth-child(3) {
  width: 6px;
  height: 6px;
  left: 32px;
  animation-delay: -5s;
  animation: explosionAnimation 0.7s ease-out infinite;
}
.explosion:nth-child(4) {
  width: 10px;
  height: 10px;
  left: 27px;
  animation-delay: -2s;
  animation: explosionAnimation 0.6s ease-out infinite;
}
.explosion:nth-child(5) {
  width: 5px;
  height: 5px;
  left: 20px;
  animation-delay: -5s;
  animation: explosionAnimation 0.8s ease-out infinite;
}
.explosion:nth-child(6) {
  width: 4px;
  height: 4x;
  left: 35px;
  animation-delay: -6s;
  animation: explosionAnimation 0.4s ease-out infinite;
}
.explosion:nth-child(7) {
  width: 8x;
  height: 8x;
  top: 50px;
  left: 0px;
  /*left: 10px;*/
  animation-delay: -3s;
  animation: ballsToScore 0.5s ease-out infinite;
}
.explosion:nth-child(8) {
  width: 9px;
  height: 9px;
  top: 50px;
  left: 0px;
  /*left: 18px;*/
  animation-delay: -4s;
  animation: ballsToScore 1s ease-out infinite;
}
.explosion:nth-child(9) {
  width: 6px;
  height: 6px;
  top: 50px;
  left: 0px;
  /*left: 32px;*/
  animation-delay: -5s;
  animation: ballsToScore 0.7s ease-out infinite;
}
.explosion:nth-child(10) {
  width: 10px;
  height: 10px;
  top: 50px;
  left: 0px;
  /*left: 27px;*/
  animation-delay: -2s;
  animation: ballsToScore 0.6s ease-out infinite;
}
.explosion:nth-child(11) {
  width: 5px;
  height: 5px;
  top: 50px;
  left: 0px;
  /*left: 20px;*/
  animation-delay: -5s;
  animation: ballsToScore 0.8s ease-out infinite;
}
.explosion:nth-child(12) {
  width: 4px;
  height: 4x;
  top: 50px;
  left: 0px;
  /*left: 35px;*/
  animation-delay: -6s;
  animation: ballsToScore 0.4s ease-out infinite;
}

.bubbles {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: var(--buttonColor);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: moveToTop 1s ease-in-out -infinite;
}
.bubbles:nth-child(1) {
  width: 12x;
  height: 12x;
  left: 35px;
  animation-delay: -3s;
  animation: moveToTop 3s ease-in-out infinite;
}
.bubbles:nth-child(2) {
  width: 18px;
  height: 18px;
  left: 18px;
  animation-delay: -4s;
  animation: moveToTop 5s ease-in-out infinite;
}
.bubbles:nth-child(3) {
  width: 6px;
  height: 6px;
  left: 32px;
  animation-delay: -5s;
  animation: moveToTop 4s ease-in-out infinite;
}
.bubbles:nth-child(4) {
  width: 10px;
  height: 10px;
  left: 27px;
  animation-delay: -2s;
  animation: moveToTop 2s ease-in-out infinite;
}
/* .bubbles:nth-child(5) {
  width: 31px;
  height: 31px;
  left: 20px;
  animation-delay: -5s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(6) {
  width: 19px;
  height: 19x;
  left: 35px;
  animation-delay: -6s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(7) {
  width: 8px;
  height: 8px;
  left: 20px;
  animation-delay: -7s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(8) {
  width: 35px;
  height: 35px;
  left: 50px;
  animation-delay: -10s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(9) {
  width: 20px;
  height: 20px;
  left: 5px;
  animation-delay: -3s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(10) {
  width: 26px;
  height: 26px;
  left: 34px;
  animation-delay: -6s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(11) {
  width: 18px;
  height: 18px;
  left: 28px;
  animation-delay: -7s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
}
.bubbles:nth-child(12) {
  width: 42px;
  height: 42px;
  left: 42px;
  animation-delay: -9s;

  animation: moveToTop 2s ease-in-out - 2s infinite;
} */

/* @for $i from 0 through 15 {
  li:nth-child(#{$i}) {
    $width: 15 + random(15) + px;
    left: 15 + random(70) + px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $width;
    height: $width;
    animation: moveToTop  #{random(6) + 3}s ease-in-out -#{random(5000) / 1000}s
      infinite;
  }
} */

@keyframes rotate {
  50% {
    border-radius: 45% / 42% 38% 58% 49%;
  }
  100% {
    transform: translate(-50%, -50%) rotate(720deg);
  }
}

@keyframes moveToTop {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
    transform: translate(-55%, 30px);
  }
}

@keyframes ballsToScore {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
    transform: translate(-110px, 150px);
  }
}
@keyframes explosionAnimation {
  60% {
    opacity: 1;
    transform: translate(20px, -40px);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
    transform: translate(38px, -5px);
  }
}

@keyframes hueRotate {
  100% {
    /* filter: contrast(0) hue-rotate(360deg); */
    filter: contrast(15) hue-rotate(360deg);
  }
}
</style>
    <!-- checkAnswer(index, event) {
      // console.log(
      //   "this.arrayOfImages.indexOf(this.points[this.pointsArrayOrder[this.$parent.pointNum]].imageBase64",
      //   this.arrayOfImages.indexOf(
      //     this.points[this.pointsArrayOrder[this.$parent.pointNum]].imageBase64
      //   )
      // );
      // REPONSE EST JUSTE
      if (
        index ==
        this.arrayOfImages.indexOf(
          this.points[this.pointsArrayOrder[this.$parent.pointNum]].imageBase64
        )
      ) {
        event.target.parentElement.classList.add("greenBorder");
        this.$parent.errorNumber = this.$parent.errorNumber + 5;
        this.$parent.showStarUp("5+");
        this.$parent.showCorrectAnswerIcon = true;
        var audio = new Audio(this.correctAnswerSound);
        audio.play();
        this.$parent.checkElementPicked();
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        setTimeout(() => {
          this.$parent.showPointInformation = true;
          localStorage.setItem(
            "showPointInformation",
            this.showPointInformation
          );
          this.$parent.showCorrectAnswerIcon = false;

          this.$parent.showButtonInfoScreen = true;

          // setTimeout(() => {
          //   this.$parent.showButtonInfoScreen = true;
          // }, 3000);
          this.$parent.showPickedItem = false;
          localStorage.setItem("showPickedItem", this.showPickedItem);

          this.$parent.showMainScreen = false;
          localStorage.setItem("showMainScreen", this.showMainScreen);

          // this.nextPointNumber();
        }, 1000);

        // REPONSE EST FAUSSE
      } else {
        event.target.parentElement.classList.add("redBorder");
        this.$parent.errorNumber = this.$parent.errorNumber - 10;
        this.$parent.$refs.pointsCounting.classList.add("redColor");
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        this.$parent.showWongAnswerIcon = true;

        audio = new Audio(this.wongAnswerSound);
        audio.play();

        setTimeout(() => {
          //   console.log("b4 remove redborder");
          event.target.parentElement.classList.remove("redBorder");
          // this.$refs.checkAnswerButton.classList.remove("redBorder");
          this.$parent.$refs.pointsCounting.classList.remove("redColor");
          this.$parent.showWongAnswerIcon = false;
        }, 1000);
      }
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            checkAnswer: "Vérifier",
            chooseImageSeen: "Depuis ce point, je vois le visuel ci-dessus",
            imageNumber: "Visuel nº",
            goThere: "Rends-toi sur place.",
            pointNumber: "Sur la carte, trouve le numéro:",
            explaination:
              "Parmi les " +
              this.arrayOfImages.length +
              " visuels ci-dessous, lequel vois-tu de ce point ?",
          };
          break;
        case "en":
          this.translation = {
            checkAnswer: "Check answer",
            chooseImageSeen: "From this point, I can see the image above",
            imageNumber: "Image no.",
            goThere: "Go there.",
            pointNumber: "On the map, find the number:",
            explaination:
              "Among the " +
              this.arrayOfImages.length +
              " images below, which one can you see from this point?",
          };
          break;
        case "de":
          this.translation = {
            checkAnswer: "Überprüfen",
            chooseImageSeen: "Von diesem Punkt aus sehe ich das obige Bild",
            imageNumber: "Bild Nr.",
            goThere: "Gehe dorthin.",
            pointNumber: "Finde die Nummer auf der Karte:",
            explaination:
              "Welches der folgenden " +
              this.arrayOfImages.length +
              " Bilder siehst du von diesem Punkt aus?",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            checkAnswer: "Check answer",
            chooseImageSeen: "From this point, I can see the image above",
            imageNumber: "Image no.",
            goThere: "Go there.",
            pointNumber: "On the map, find the number:",
            explaination:
              "Among the " +
              this.arrayOfImages.length +
              " images below, which one can you see from this point?",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
.pointNumber {
  margin: 0 auto;
  border: yellow solid 2px;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  font-size: 36px;
  padding-right: 1px;
  padding-top: 4px;
  font-weight: bold;
}
.imageDiv {
  width: 86%;
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
  border: 5px solid white;
}
.fullWidth {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
  background-color: #83cb97;
  margin-bottom: 45px;
  height: 56px;
  border: solid white 5px;
  color: white;
  font-size: 20px;
}
.greenBorder {
  border: solid rgb(5, 253, 5) 5px !important;
  /* height: 55px !important;
  width: 55px !important;
  bottom: 8px;
  font-size: 27px !important;
  position: relative;*/
}
.redBorder {
  border: solid rgb(253, 5, 5) 5px !important;
  /* height: 55px !important;
  width: 55px !important;
  bottom: 8px;
  font-size: 27px !important;
  position: relative; */
}
.spanNumbers {
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Cabin";
  padding-top: 3px;
}
.rowClickablePoints {
  display: flex;
  justify-content: center;
}
.arrowRight {
  height: 50px;
  width: 10px;
  background: #83cb97;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-top-width: 5px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  border-color: white;
  border-style: solid;
  top: 70px;
}
.arrowLeft {
  height: 50px;
  width: 10px;
  background: #83cb97;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 0px;
  border-color: white;
  border-style: solid;
  top: 70px;
}

.slidesCounting {
  width: auto;
  font-size: 21px;
  padding-top: 5px;
  background: #83cb978f;
  color: white;
  text-align: center;
  font-weight: bold;
  border: solid white 4px;
  border-radius: 4px;
  position: absolute;
  bottom: 229px;
  z-index: 2;
  padding: 8px;
  font-family: system-ui;
}

.bi-arrow-right-short,
.bi-arrow-left-short {
  font-size: 38px;
  color: white;
  position: relative;
  height: 40px;
  width: 40px;
  top: 0px;
}
.img-fluid {
  width: 100vw;
  height: auto;
}
#carousel-1 {
  /* background: rgb(171, 171, 171); */
  text-shadow: rgb(51 51 51) 1px 1px 2px;
  min-height: 230px;
  margin: 0px auto;
  width: 100vw;
  display: block;
  position: relative;
  /* max-width: 330px; */
  overflow: hidden;
}
.numbersInCercle {
  height: 40px;
  width: 40px;
  border-radius: 60px;
  background: #83cb97;
  opacity: 0.8;
  border: solid rgb(0 0 0) 1px;
  font-size: 22px;
  color: white;
}
.activeClassPoint {
  opacity: 0.7;
  height: 50px;
  width: 50px;
  /* border: solid red 2px; */
  position: relative;
  font-size: 28px;
  bottom: 6px;
}
.smallPoint {
  width: 5px;
  height: 5px;
  border-radius: 60px;
  background: white;
  opacity: 0.8;
}
.activePoint {
  opacity: 0.7;
  background: rgb(30, 255, 0);
  height: 10px;
  width: 10px;
  bottom: 3px;
  right: 2px;
  position: relative;
}
.carousel {
  width: 640px;
  height: 200px;
}
</style>


 -->
