<template >
  <div style="color: #0070b0; font-weight: bold; font-family: system-ui">
    <div class="row m-0 fixed-bottom time rtl bottomBar">
      <div class="col-4 text-center errorsTimeMap" ref="pointsCounting">
        <!-- <span id="erreurs">{{ lang }}{{ translation.mistakes }}</span> -->
        <div style="color: yellow; padding-top: 10px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            fill="currentColor"
            class="bi bi-star-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        </div>
        <div style="direction: ltr">{{ errorNumber }}{{ lang }}</div>

        <div v-if="showStar" class="position-relative starMoveUp">
          <svg
            style="color: yellow; position: relative; left: -13px"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-star-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
          <span
            class="position-relative"
            style="left: 23px; top: 4px; color: black"
            >{{ numberOfPoints }}</span
          >
        </div>
      </div>
      <div class="col-4 text-center errorsTimeMap">
        <div class="evolutionBottomDiv">
          {{ pointNumForEvolution }}
          <img
            style="bottom: 74px; height: 78px; right: 4px"
            src="../../static/img/smile.png"
            alt=""
            v-if="showPointInformation"
          />
          <img
            style="bottom: 70px"
            src="../../static/img/thinking.png"
            alt=""
            v-if="
              (showQuestion &&
                !showPointInformation &&
                !showMainScreen &&
                !showWongAnswerIcon &&
                !showCorrectAnswerIcon) ||
              (showMultipleMatch &&
                showPickedItem &&
                !showWongAnswerIcon &&
                !showCorrectAnswerIcon)
            "
          />
          <img
            style="bottom: 78px; height: 70px"
            src="../../static/img/proud.png"
            alt=""
            v-if="
              (showPuzzle && showPickedItem) ||
              (showPhotoComponent && showPickedItem)
            "
          />
          <img
            style="bottom: 78px; height: 70px"
            src="../../static/img/disgusted.png"
            alt=""
            v-if="showWongAnswerIcon"
          />
          <img
            style="bottom: 98px; height: 100px; left: 10px"
            src="../../static/img/enthusiastic.png"
            alt=""
            v-if="showLastDialog || showCorrectAnswerIcon"
          />
        </div>
        <div style="position: relative; bottom: 32px">
          {{ lang }} {{ pointNumForEvolution }}/{{
            Object.values(pointsArrayOrder).length
          }}
        </div>

        <!-- <span id="evolution">{{ translation.evolution }}</span> -->
      </div>
      <!-- <div class="col-5 text-center errorsTimeMap pr-0">
        {{ secondsToTime }}
        <span id="runningTime">{{ translation.runningTime }}</span>
      </div> -->
      <div class="col-4 text-center errorsTimeMap pt-4" @click="slideMapInn">
        <!-- {{ translation.map }} -->
        <svg
          class="bi bi-map"
          width="20px"
          height="20px"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15.817.613A.5.5 0 0116 1v13a.5.5 0 01-.402.49l-5 1a.502.502 0 01-.196 0L5.5 14.51l-4.902.98A.5.5 0 010 15V2a.5.5 0 01.402-.49l5-1a.5.5 0 01.196 0l4.902.98 4.902-.98a.5.5 0 01.415.103zM10 2.41l-4-.8v11.98l4 .8V2.41zm1 11.98l4-.8V1.61l-4 .8v11.98zm-6-.8V1.61l-4 .8v11.98l4-.8z"
            clip-rule="evenodd"
          />
        </svg>
        <span id="carte">{{ translation.map }}</span>
      </div>
    </div>
    <!-- **************END OF BOTTOM BAR********* -->

    <!-- **************MAIN SCREEN********* -->
    <div
      v-if="showMainScreen"
      style="
        display: block;
        margin: 0px;
        padding: 0px;
        min-height: calc(100vh - 150px);
        margin-bottom: 55px;
      "
      class="container d-flex"
    >
      <div
        class="row p-0 m-0"
        style="max-width: 590px; display: flex; justify-content: center"
      >
        <div
          class="row align-items-center justify-content-center align-self-center m-0 p-0"
          style="min-height: 40%"
        >
          <component :is="navigationScreen"></component>
        </div>
      </div>
    </div>
    <!-- **************END OF MAIN SCREEN********* -->

    <div v-if="showPickedItem" style="display: flex; justify-content: center">
      <Question v-if="showQuestion" />
      <MultipleMatch v-if="showMultipleMatch" />
      <PhotoComponent v-if="showPhotoComponent" />
      <Puzzle v-if="showPuzzle" />
    </div>
    <!-- **************INFORMATION SCREEN********* -->

    <div v-if="showPointInformation" class="over">
      <div class="myModal mb-2 modalInformationPoint" style="display: block">
        <div class="h-100">
          <div class="row m-0">
            <!-- <div class="col-12 d-flex align-items-start">
              <div class="mb-4 text-right ">
                <strong>{{ translation.didYouKnow }}</strong>
              </div>
            </div> -->

            <!-- PLAY THE AUDIO FILE -->
            <div class="col-12 align-self-center p-2">
              <div class="mt-1 mb-4 d-flex justify-content-center">
                <audio
                  v-if="
                    arrayOfAudioUrl &&
                    arrayOfAudioUrl[0] != undefined &&
                    arrayOfAudioUrl[1] != undefined
                  "
                  id="audio"
                  controls
                  autoplay
                >
                  <!-- :src="arrayOfAudioUrl[pointsArrayOrder[pointNum]]" -->

                  <source :src="arrayOfAudioUrl" type="audio/mp3" />
                </audio>
              </div>
              <!-- SHOW THE WRITTEN INFORMATION -->
              <div
                style="white-space: pre-line; font-size: 18px; color: white"
                class="p-4"
              >
                {{ newInformationPointString }}
              </div>

              <div v-if="gameParameters.withVideo">
                <div
                  id="videoDiv"
                  v-if="points[pointsArrayOrder[pointNum]].withVideo"
                >
                  <youtube
                    v-if="points[pointsArrayOrder[pointNum]].withVideo"
                    :video-id="videoId"
                    :player-vars="playerVars"
                    @playing="playing"
                    style="position: relative; width: 100%"
                  ></youtube>
                  <!-- <div v-else class="pointInfoDiv">
                  {{ translation.didYouKnow }}
                  <br />
                  <br />
                  {{ points[pointNum + 1].pointInformation }}
                </div> -->
                </div>
              </div>
            </div>
            <div class="col-12 d-flex align-items-end">
              <button
                v-if="showButtonInfoScreen"
                class="btn btn-primary btn-lg btn3d continueButton font-weight-bold"
                style="
                  background-color: #0090e3;
                  margin: 45px;
                  height: 53px;
                  font-family: VarelaRound;
                "
                @click="showPickedItemDiv"
              >
                {{ translation.goToQuestions }}
              </button>
              <button
                v-else
                id="buttonContinueDisabled"
                class="btn btn-primary btn-lg btn3d continueButton active font-weight-bold"
                style="
                  background-color: #0090e3;
                  margin: 30px;
                  height: 53px;
                  font-family: VarelaRound;
                "
                @click="showPickedItemDiv"
              >
                {{ translation.goToQuestions }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- **************END OF INFORMATION SCREEN********* -->

    <!-- <div v-if="alertSkipPoint" class="over">
      <div class="myModal p-4 mb-5 red container d-flex">
        <div class="row justify-content-center align-self-center">
          <div class="h5 mb-4 text-center col-12">
            {{ translation.skipPointBeware }}
          </div>
          <div class="col-6">
            <div
              class="font-weight-bold h6 text-center"
              style="width: 100%"
              @click="skipPoint()"
            >
              {{ translation.skipPoint }}
            </div>
          </div>
          <div class="col-6">
            <div
              class="font-weight-bold h6 text-center"
              style="
                width: 100%;
                padding-top: 9px;
                color: red;
                background: white;
                height: 38px;
                border-radius: 30px;
              "
              @click="alertSkipPoint = false"
            >
              {{ translation.back }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div
      v-if="showLastDialog"
      class="over container d-flex justify-content-center align-self-center lastDialog"
    >
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div
        class="myModal row p-2 justify-content-center align-items-center"
        style="
          background: white;
          top: 145px;
          color: rgb(51, 105, 188);
          width: 320px;
          height: 480px;
          position: relative;
        "
        @click="showResults"
      >
        <audio id="audio" controls loop autoplay style="display: none">
          <source src="../../static/sounds/endOfGame.mp3" type="audio/mp3" />
        </audio>
        <img src="../../static/img/happy.gif" class="happyGif" alt="" />

        <div class="h3 p-2 col-12 text-center font-weight-bold">
          {{ translation.wellDone }} {{ userName }} !
        </div>
        <div class="h4 p-2 font-weight-bold col-12 text-right text-center">
          {{ translation.finishedTheGame }}
        </div>
        <!-- <div class="h3 p-2 col-12 font-italic text-center">
          {{ translation.errorNumber }} {{ errorNumber }}
        </div> -->
        <div class="h3 p-2 col-12 font-italic text-center rtl">
          <img
            src="../../static/img/starEnd.png"
            alt=""
            style="height: 140px"
          />
          <div id="pointsNumber">
            {{ errorNumber }}
          </div>
          <div
            class="h5 font-weight-bold p-2 col-12 text-right rtl text-center"
          >
            {{ translation.clickToResults }}
          </div>

          <!--   {{ translation.errorNumber }} -->
        </div>
      </div>
    </div>

    <div id="map">
      <span @click="removeMap" class="removeMapButton">
        <button type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </span>
      <div v-if="showWarningB4Location" class="over d-flex">
        <div
          class="myModal p-3 mb-2 modalInformationPoint justify-content-center align-self-center"
          style="height: auto"
        >
          <div class="h-100 d-flex">
            <div class="row">
              <div class="col-12 align-self-center text-left">
                <div style="white-space: pre-line; font-size: 18px">
                  Attention!
                </div>
                <div style="white-space: pre-line; font-size: 18px">
                  Une fois que vous aurez cliqué sur le bouton, il sera
                  indispensable d'autoriser l'accès à votre géolocalisation si
                  vous désirez voir votre position sur la carte.
                </div>
              </div>
              <div class="col-12 d-flex align-items-end">
                <button
                  class="btn btn-primary btn-lg btn3d continueButton font-weight-bold"
                  style="
                    background-color: rgb(247 155 155);
                    margin: 45px;
                    height: 53px;
                    font-family: VarelaRound;
                  "
                  @click="
                    showCurrentPosition();
                    showWarningB4Location = false;
                  "
                >
                  {{ translation.goToQuestions }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showCurrentPositionButton"
        @click="showWarningB4Location = true"
        class="currentPosButton"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          fill="currentColor"
          class="bi bi-geo-alt-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
          />
        </svg>
      </div>
      <div v-else @click="stopLocationUpdates" class="currentPosButton">
        Enlever la position
      </div>
      <GmapMap
        :center="{
          lat: Number(mapParameters.lat),
          lng: Number(mapParameters.lng),
        }"
        :zoom="Number(mapParameters.zoom)"
        map-type-id="satellite"
        :tilt="0"
        style="width: 100vw; height: 100vh"
        :styles="myMapStyle"
        :options="{
          zoomControl: false,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: true,
          fullscreenControl: false,
          disableDefaultUi: false,
        }"
      >
        <GmapMarker
          style="margin-right: 10px !important"
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :label="{ text: `${index + 1}`, color: 'white', fontSize: '9px' }"
          @click="center = m.position"
        />
        <GmapMarker
          v-if="showPosMarker"
          style="
            margin-right: 10px !important;
            height: 50px !important;
            width: 50px !important;
          "
          :clickable="true"
          @click="center = m.position"
          :position="myCurrentPosition"
          :icon="iconImage"
        />
      </GmapMap>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Question from "./questions";
import Carousel from "./carousel";
import Puzzle from "./puzzle";
import MultipleMatch from "./multipleMatch";
import PhotoComponent from "./photoComponent";
import ImagesList from "./imagesList";
import DynamicMap from "./dynamicMap";
import MissingWord from "./missingWord";

export default {
  components: {
    Question,
    Carousel,
    Puzzle,
    MultipleMatch,
    PhotoComponent,
    ImagesList,
    DynamicMap,
    MissingWord,
  },
  data() {
    return {
      numberOfPoints: "5+",
      showCorrectAnswerIcon: false,
      showWongAnswerIcon: false,
      showStar: false,
      playerVars: {
        autoplay: 1,
      },
      showWarningB4Location: false,
      pickedItem: "",
      showMultipleMatch: false,
      showPhotoComponent: false,
      showPuzzle: false,
      showQuestion: false,
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      wongAnswerSound: require("../../static/sounds/wrongAnswer.mp3"),
      // alertSkipPoint: false,
      imageUrl: "",
      showPosMarker: false,
      showButtonInfoScreen: false,
      iconImage: {
        url: require("../../static/img/circle.png"),
      },
      watchPositionId: null,
      position: "Position",
      showCurrentPositionButton: true,
      myCurrentPosition: "",
      myMapStyle: {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      firstQuestion: 1,
      showPointInformation: false,
      showMainScreen: true,
      showPickedItem: false,
      secondsToTime: "00:00:00",
      gameSecondToTime: "",
      totalSeconds: 0,
      startTime: Date.now(),
      endTime: "",
      showMap: false,
      showLastDialog: false,
      key: "",
      questiomMultipleChoices: true,
      // missingWordAnswer: "",
      loading: false,
      src: "",
      timeNow: "",
      questionNumber: 0,
      errorNumber: 20,
      pointNum: 0,
      pointNumForEvolution: 1,
      letter: "",
      allLetters: "",
      gameTimeInSecond: "",
      userKey: "",
      translationMissingWord: "Mot manquant sur l'image",
      translation: {
        clickToResults: "Cliquez pour les résultats",
        back: "Fermer",
        skipPoint: "Passer au point suivant",
        skipPointBeware:
          "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, cela vous coûtera 10 points.",
        mistakes: "Points",
        runningTime: "Temps écoulé",
        map: "Carte",
        pointNumber: "Sur la carte, repérez le nº",
        findMissingWord:
          "Rends-toi sur place et trouve l'inscription qui se trouve sur l'image ci-dessous.",
        didYouKnow: "Le saviez-vous ?",
        goToQuestions: "Continuer",
        wellDone: "Bien joué",
        correctAnswer: "C'est une bonne réponse !",
        continue: "Cliquez pour continuer.",
        wrongAnswer: "Ce n'est pas la bonne réponse.",
        tryAgain: "Cliquez pour réessayer.",
        visitOf: "Nous espérons que vous avez apprécié votre visite de",
        yourResults: "Vos résultats sont :",
        errorNumber: "Nombre de points :",
        totalTime: "Temps total :",
        goToRanking:
          "Avant de partir, vous pouvez aller jeter un coup d'oeil sur les résultats et voir en quelle position vous êtes arrivé(e) !",
        evolution: "Evolution",
        results: "Résultats",
        first: "",
        afterFirst: "",
        finishedTheGame: "Tu as terminé le parcours et voici ton résultat :",
      },
    };
  },
  mounted() {
    // console.log("Date.now()", Date.now());
    // console.log("this.startTime", this.startTime);

    if (this.gameParameters) {
      console.log("ok");
    } else {
      console.log("bug");
      this.reopenGame();
    }
    this.userKey = localStorage.getItem("userKey");
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.countSeconds();
    if (localStorage.getItem("pointNumber")) {
      if (localStorage.getItem("pointNumber")) {
        this.pointNum = localStorage.getItem("pointNumber");
        this.pointNumForEvolution = localStorage.getItem(
          "pointNumForEvolution"
        );
      } else {
        this.pointNum = 0;
        this.pointNumForEvolution = 1;
      }
      this.startTime = localStorage.getItem("startTime");
      if (localStorage.getItem("errorNumber")) {
        //console.log(          "localStorage.getItem(errorNumber)",          localStorage.getItem("errorNumber")        );

        this.errorNumber = JSON.parse(localStorage.getItem("errorNumber"));
      } else {
        this.errorNumber = 20;
      }
      if (localStorage.getItem("questionNumber")) {
        this.questionNumber = JSON.parse(
          localStorage.getItem("questionNumber")
        );
      } else {
        this.questionNumber = 0;
      }
      this.firstQuestion = localStorage.getItem("firstQuestion");
      this.showMainScreen = JSON.parse(localStorage.getItem("showMainScreen"));
      this.showPickedItem = JSON.parse(localStorage.getItem("showPickedItem"));
      this.showPointInformation = JSON.parse(
        localStorage.getItem("showPointInformation")
      );
      this.pickedItem = localStorage.getItem("pickedItem");
      this.showPuzzle = JSON.parse(localStorage.getItem("showPuzzle"));
      this.showQuestion = JSON.parse(localStorage.getItem("showQuestion"));
      this.showMultipleMatch = JSON.parse(
        localStorage.getItem("showMultipleMatch")
      );
      this.showPhotoComponent = JSON.parse(
        localStorage.getItem("showPhotoComponent")
      );
      // console.log("this.questionNumber", this.questionNumber);
      // console.log("this.showMainScreen", this.showMainScreen);
      // console.log("this.showPickedItem", this.showPickedItem);
      // console.log("this.showPointInformation", this.showPointInformation);
      // console.log("this.pickedItem", this.pickedItem);
      // console.log("this.showPuzzle", this.showPuzzle);
      // console.log("this.showQuestion", this.showQuestion);
      // console.log("this.showMultipleMatch", this.showMultipleMatch);
      // console.log("this.showPhotoComponent", this.showPhotoComponent);
    } else {
      localStorage.setItem("showMainScreen", this.showMainScreen);
      localStorage.setItem("showPointInformation", this.showPointInformation);
      localStorage.setItem("showPickedItem", this.showPickedItem);
      localStorage.setItem("startTime", this.startTime);
      localStorage.setItem("showPuzzle", this.showPuzzle);
      localStorage.setItem("showQuestion", this.showQuestion);
      localStorage.setItem("showMultipleMatch", this.showMultipleMatch);
      localStorage.setItem("showPhotoComponent", this.showPhotoComponent);
    }
  },
  computed: {
    navigationScreen() {
      // This method should return the component based on your database response
      switch (
        this.points[this.pointsArrayOrder[this.pointNum]].navigationScreen
      ) {
        case "dynamicMap":
          return "DynamicMap";
        case "missingWord":
          return "MissingWord";
        case "imagesList":
          return "ImagesList";
        case "carousel":
          return "Carousel";
        default:
          return "ImagesList"; // Default component
      }
    },
    videoId() {
      if (this.points[this.pointsArrayOrder[this.pointNum]].videoUrl) {
        // console.log(
        //   "this.points[this.pointsArrayOrder[this.pointNum]]",
        //   this.points[this.pointsArrayOrder[this.pointNum]]
        // );
        localStorage.setItem(
          "videoUrl",
          this.points[this.pointsArrayOrder[this.pointNum]].videoUrl
        );
        return this.points[this.pointsArrayOrder[this.pointNum]].videoUrl;
      } else {
        return localStorage.getItem("videoUrl");
      }
    },
    showCarousel() {
      return this.$store.getters.gameParameters.carousel;
    },
    showImagesList() {
      return this.$store.getters.gameParameters.imagesList;
    },
    newInformationPointString() {
      return this.points[
        this.pointsArrayOrder[this.pointNum]
      ].pointInformation.replaceAll("\n", "\n");
    },
    numberOfQUestionPerPoint() {
      return Math.floor(
        this.$store.getters.questionsShuffled.length /
          (this.$store.getters.points.length - 1)
      );
    },
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    userName() {
      return this.$store.getters.user.userName;
    },
    arrayOfAudioUrl() {
      return this.$store.getters.arrayOfAudioUrl[
        this.pointsArrayOrder[this.pointNum] - 1
      ];
    },
    arrayOfPointInfo() {
      return this.$store.getters.arrayOfPointInfo;
    },
    mapParameters() {
      return this.$store.getters.mapParameters;
    },
    gameParameters() {
      // console.log(
      //   "this.$store.getters.gameParameters;",
      //   this.$store.getters.gameParameters
      // );
      return this.$store.getters.gameParameters;
    },
    group() {
      return this.$store.getters.group;
    },
    points() {
      // console.log("this.$store.getters.points", this.$store.getters.points);
      return this.$store.getters.points;
    },
    markers() {
      var markers = this.$store.getters.markers;
      var markersArray = [];
      markers.forEach((marker) => {
        markersArray.push({ position: { lat: marker.lat, lng: marker.lng } });
      });
      // console.log("markersArray", markersArray);
      return markersArray;
    },
    questions() {
      return this.$store.getters.questions;
    },
    // getImgUrl() {
    //   if (
    //     Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
    //       .imageBase64
    //   ) {
    //     return Object.values(this.points)[
    //       this.pointsArrayOrder[this.pointNum] - 1
    //     ].imageBase64;
    //   } else {
    //     return Object.values(this.points)[
    //       this.pointsArrayOrder[this.pointNum] - 1
    //     ].imageUrl;
    //   }
    // },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        return this.$store.getters.pointsArrayOrder;
      }
    },
  },
  methods: {
    showStarUp(numberOfPoints) {
      this.numberOfPoints = numberOfPoints;
      this.showStar = true;
      setTimeout((_) => {
        this.showStar = false;
      }, 2000);
    },
    playing() {
      console.log("o/ we are watching!!!");
    },
    reopenGame() {
      if (localStorage.getItem("navigationName")) {
        this.$router.push("/" + localStorage.getItem("navigationName"));
        location.reload();
      } else {
        this.$router.push("/");
        location.reload();
      }
    },
    // skipPoint() {
    //   this.checkElementPicked();
    //   this.storePointNumber();
    //   this.errorNumber = this.errorNumber - 10;
    //   localStorage.setItem("errorNumber", this.errorNumber);

    //   this.showPointInformation = true;
    //   localStorage.setItem("showPointInformation", this.showPointInformation);

    //   setTimeout((_) => {
    //     document
    //       .getElementById("buttonContinueDisabled")
    //       .classList.remove("active");
    //     this.showButtonInfoScreen = true;
    //   }, 3000);
    //   this.showPickedItem = false;
    //   localStorage.setItem("showPickedItem", this.showPickedItem);

    //   this.showMainScreen = false;
    //   localStorage.setItem("showMainScreen", this.showMainScreen);

    //   this.alertSkipPoint = false;
    //   localStorage.setItem("firstQuestion", 1);

    //   this.startTime = this.startTime - 60 * 10 * 1000;
    //   this.$store.dispatch("addEvolutionToDB", {
    //     pointNumber: this.pointNum,
    //     userKey: this.userKey,
    //     navigationName: this.gameParameters.navigationName,
    //   });
    //   this.firstQuestion = 1;
    //   localStorage.setItem("firstQuestion", 1);
    //   // this.$store.dispatch("addEvolutionToDB", {
    //   //   pointNumber: this.pointNum,
    //   //   userKey: this.userKey,
    //   //   navigationName: this.gameParameters.navigationName,
    //   // });
    // },
    // showAlertSkipPoint() {
    //   this.alertSkipPoint = true;
    // },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    stopLocationUpdates() {
      // console.log(
      //   "stopLocationUpdates clicked this.watchPositionId",
      //   this.watchPositionId
      // );
      this.showPosMarker = false;
      this.showCurrentPositionButton = true;
      navigator.geolocation.clearWatch(this.watchPositionId);
      // console.log(
      //   "stopLocationUpdates clicked this.watchPositionId",
      //   this.watchPositionId
      // );
    },
    showCurrentPosition() {
      if (navigator.geolocation) {
        // console.log("navigator.geolocation OK");
        this.showCurrentPositionButton = false;
        this.watchPositionId = navigator.geolocation.watchPosition(
          (position) => {
            let pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            // console.log("position", position);
            this.myCurrentPosition = pos;
            // console.log("this.myCurrentPosition", this.myCurrentPosition);
            this.showPosMarker = true;
          },
          () => {
            handleLocationError(true, infoWindow, map.getCenter());
          },
          { enableHighAccuracy: true }
        );
      } else {
        // Browser doesn't support Geolocation
        alert("Browser doesn't support Geolocation");
        // handleLocationError(false, infoWindow, map.getCenter());
      }
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translationMissingWord = "Mot manquant sur l'image";
          this.translation = {
            clickToResults: "Cliquez pour les résultats",
            back: "Fermer",
            skipPoint: "Passer au point suivant",
            skipPointBeware:
              "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, cela vous coûtera 10 points.",
            mistakes: "Points",
            runningTime: "Temps écoulé",
            map: "Carte",
            pointNumber: "Sur la carte, repérez le nº",
            findMissingWord:
              "Rends-toi sur place et trouve l'inscription qui se trouve sur l'image ci-dessous.",
            didYouKnow: "Le saviez-vous ?",
            goToQuestions: "Continuer",
            wellDone: "Bien joué",
            correctAnswer: "C'est une bonne réponse !",
            continue: "Cliquez pour continuer.",
            wrongAnswer: "Ce n'est pas la bonne réponse.",
            tryAgain: "Cliquez pour réessayer.",
            visitOf: "Nous espérons que vous avez apprécié votre visite de",
            yourResults: "Vos résultats sont :",
            errorNumber: "Nombre de points :",
            totalTime: "Temps total :",
            goToRanking:
              "Avant de partir, vous pouvez aller jeter un coup d'oeil sur les résultats et voir en quelle position vous êtes arrivé(e) !",
            evolution: "Evolution",
            results: "Résultats",
            first: "",
            afterFirst: "",
            finishedTheGame:
              "Tu as terminé le parcours et voici ton résultat :",
          };
          break;
        case "en":
          this.translationMissingWord = "Missing word on the picture";
          this.translation = {
            clickToResults: "Click for results",
            back: "Close",
            skipPoint: "Skip to the next point",
            skipPointBeware:
              "Attention! If you cannot find the sign or the answer, you can move to the next point, but it will cost you 10 points.",
            mistakes: "Points",
            runningTime: "Elapsed time",
            map: "Map",
            pointNumber: "On the map, find the nº",
            findMissingWord:
              "Go there and find the inscription shown in the image below.",
            didYouKnow: "Did you know?",
            goToQuestions: "Proceed",
            wellDone: "Well done",
            correctAnswer: "That is the correct answer!",
            continue: "Click to continue.",
            wrongAnswer: "That is not the correct answer.",
            tryAgain: "Click to try again.",
            visitOf: "We hope you enjoyed your visit of",
            yourResults: "Your results are:",
            errorNumber: "Number of points:",
            totalTime: "Total time:",
            goToRanking:
              "Before you leave, you can check out the results and see where you finished!",
            evolution: "Evolution",
            results: "Results",
            first: "",
            afterFirst: "",
            finishedTheGame:
              "You have finished the course, here is your result:",
          };
          break;
        case "de":
          this.translationMissingWord = "Fehlendes Wort auf dem Bild";
          this.translation = {
            clickToResults: "Klicken für Ergebnisse",
            back: "Schließen",
            skipPoint: "Zum nächsten Punkt springen",
            skipPointBeware:
              "Achtung! Wenn Sie das Schild oder die Antwort nicht finden können, können Sie zum nächsten Punkt gehen, es kostet Sie jedoch 10 Punkte.",
            mistakes: "Punkte",
            runningTime: "Verstrichene Zeit",
            map: "Karte",
            pointNumber: "Auf der Karte, finde die Nr.",
            findMissingWord:
              "Gehe dorthin und finde die auf dem Bild unten gezeigte Inschrift.",
            didYouKnow: "Wussten Sie schon?",
            goToQuestions: "Fortfahren",
            wellDone: "Gut gemacht",
            correctAnswer: "Das ist die richtige Antwort!",
            continue: "Klicken, um fortzufahren.",
            wrongAnswer: "Das ist nicht die richtige Antwort.",
            tryAgain: "Klicken, um es erneut zu versuchen.",
            visitOf: "Wir hoffen, Sie haben Ihren Besuch genossen",
            yourResults: "Ihre Ergebnisse sind:",
            errorNumber: "Anzahl der Punkte:",
            totalTime: "Gesamtzeit:",
            goToRanking:
              "Bevor Sie gehen, können Sie sich die Ergebnisse ansehen und sehen, wo Sie gelandet sind!",
            evolution: "Entwicklung",
            results: "Ergebnisse",
            first: "",
            afterFirst: "",
            finishedTheGame:
              "Sie haben den Kurs beendet, hier ist Ihr Ergebnis:",
          };
          break;
        default:
          // Default case
          break;
      }
    },

    slideMapInn() {
      document.getElementById("map").className = "showMap";
    },
    removeMap() {
      document.getElementById("map").classList.remove("showMap");
    },
    // showPickedItemDiv() {
    //   setTimeout((_) => {
    //     this.showPickedItem = true;
    //     localStorage.setItem("showPickedItem", this.showPickedItem);

    //     this.showMainScreen = false;
    //     localStorage.setItem("showMainScreen", this.showMainScreen);

    //     this.showPointInformation = false;
    //     localStorage.setItem("showPointInformation", this.showPointInformation);

    //     this.showButtonInfoScreen = false;
    //   }, 1000);
    // },
    /////// ******* J'AI CHANGÉ CA POUR POUVOIR PASSER DIRECTEMENT AU POINT SUIVANT SI IL N 'Y A PAS QUESTIONS*************'
    showPickedItemDiv() {
      if (localStorage.getItem("pickedItem") == "") {
        console.log("[showPickedItemDiv] no showPickedItem");

        this.nextPointNumber();
        this.showPickedItem = false;
        localStorage.setItem("showPickedItem", this.showPickedItem);

        this.showMainScreen = true;
        localStorage.setItem("showMainScreen", this.showMainScreen);

        this.showPointInformation = false;
        localStorage.setItem("showPointInformation", this.showPointInformation);

        this.showButtonInfoScreen = false;
      } else {
        setTimeout((_) => {
          console.log("[showPickedItemDiv]  YESSSSSSS");
          this.showPickedItem = true;
          localStorage.setItem("showPickedItem", this.showPickedItem);

          this.showMainScreen = false;
          localStorage.setItem("showMainScreen", this.showMainScreen);

          this.showPointInformation = false;
          localStorage.setItem(
            "showPointInformation",
            this.showPointInformation
          );

          this.showButtonInfoScreen = false;
        }, 1000);
      }
    },
    fadeInDiv() {
      if (document.getElementById("questionDiv")) {
        document.getElementById("questionDiv").className = "fadeIn";
      } else {
        console.log("[fadeInDiv] no questionDiv");
      }
    },
    countSeconds() {
      setInterval((_) => {
        this.timeNow = Date.now();
        this.totalSeconds = Math.floor((this.timeNow - this.startTime) / 1000);
        var pad = function (num, size) {
            return ("000" + num).slice(size * -1);
          },
          time = parseFloat(this.totalSeconds).toFixed(3),
          hours = Math.floor(time / 60 / 60),
          minutes = Math.floor(time / 60) % 60,
          seconds = Math.floor(time - minutes * 60);

        this.secondsToTime =
          pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
      }, 1000);
    },
    showResults() {
      // console.log("showResults");
      // localStorage.clear();
      this.$router.push("/resultsByPoints");
      // this.$store.dispatch("getResults", this.gameParameters.navigationName);
    },

    nextPointNumber() {
      // console.log(
      //   " Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1].pointNumber",
      //   Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
      //     .pointNumber
      // );
      ////////////CHECK IF LAST POINT TO SHOW END OF GAME SCREEN////////////////
      // console.log(
      //   "  this.gameParameters.lastPoint;",
      //   this.gameParameters.lastPoint
      // );
      // console.log(
      //   " Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1].pointNumber",
      //   Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
      //     .pointNumber
      // );
      if (
        Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
          .pointNumber == this.gameParameters.lastPoint
      ) {
        this.showLastDialog = true;
        // GET THE TOTAL TIME OF THE GAME
        this.endTime = Date.now();
        this.gameTimeInSecond = Math.floor(
          (this.endTime - this.startTime) / 1000
        );
        var pad = function (num, size) {
            return ("000" + num).slice(size * -1);
          },
          time = parseFloat(this.gameTimeInSecond).toFixed(3),
          hours = Math.floor(time / 60 / 60),
          minutes = Math.floor(time / 60) % 60,
          seconds = Math.floor(time - minutes * 60);

        this.gameSecondToTime =
          pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
        // SEND THE RESULTS TO THE DATABASE
        //   console.log("b4 check results - this.errorNumber", this.errorNumber);

        this.$store.dispatch("checkResults", {
          errorNumber: this.errorNumber,
          gameSecondToTime: this.gameSecondToTime,
          gameTimeInSecond: this.gameTimeInSecond,
        });
        /////////////END OF CHECK FOR LAST POINT/////////////////
      } else {
        this.pointNum++;
        this.pointNumForEvolution++;
        localStorage.setItem("pointNumber", this.pointNum);
        localStorage.setItem("pointNumForEvolution", this.pointNumForEvolution);
        localStorage.setItem(
          "withVideo",
          Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
            .withVideo
        );
        this.$store.dispatch("addEvolutionToDB", {
          pointNumber: this.pointNum,
          userKey: this.userKey,
          navigationName: this.gameParameters.navigationName,
        });
      }
    },
    checkElementPicked() {
      this.showPuzzle = false;
      localStorage.setItem("showPuzzle", this.showPuzzle);

      this.showQuestion = false;
      localStorage.setItem("showQuestion", this.showQuestion);

      this.showMultipleMatch = false;
      localStorage.setItem("showMultipleMatch", this.showMultipleMatch);

      this.showPhotoComponent = false;
      localStorage.setItem("showPhotoComponent", this.showPhotoComponent);

      this.pickedItem = Object.values(this.points)[
        this.pointsArrayOrder[this.pointNum] - 1
      ].picked;
      localStorage.setItem("pickedItem", this.pickedItem);
      switch (this.pickedItem) {
        case "Puzzle":
          this.showPuzzle = true;
          localStorage.setItem("showPuzzle", this.showPuzzle);
          break;
        case "Question":
          this.showQuestion = true;
          localStorage.setItem("showQuestion", this.showQuestion);
          break;
        case "PhotoComponent":
          this.showPhotoComponent = true;
          //   console.log("this.showPhotoComponent", this.showPhotoComponent);
          localStorage.setItem("showPhotoComponent", this.showPhotoComponent);
          break;
        case "MultipleMatch":
          this.showMultipleMatch = true;
          localStorage.setItem("showMultipleMatch", this.showMultipleMatch);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.lastDialog {
  position: fixed;
  height: 100vh !important;
  background: rgba(0, 0, 0, 0.6);
}
#pointsNumber {
  position: relative;
  bottom: 85px;
  font-size: 38px;
  font-weight: bold;
  left: -5px;
}
.happyGif {
  height: 200px;
  position: absolute;
  top: -154px;
  border-radius: 100%;
}
.evolutionBottomDiv {
  position: relative;
  bottom: 36px;
  background: white;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  color: #3369bc;
  font-size: 50px;
  border: 1px solid #3369bc;
  z-index: 15;
}
.evolutionBottomDiv img {
  height: 88px;
  z-index: 15;
  position: relative;
  bottom: 75px;
  left: 0px;
}
.bottomBar {
  height: 80px;
  max-width: 590px;
  margin: 0 auto !important;
  z-index: 2;
  background: #0070b0;
  color: white;
}
.redColor {
  color: red;
}
.starMoveUp {
  animation: moveUp 2s ease-in-out; /* Adjust the duration and easing as needed */
  bottom: 80px;
  opacity: 1;
}
@keyframes moveUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
}
.removeMapButton {
  position: absolute;
  top: 6px;
  font-size: 53px;
  z-index: 100;
  right: 8px;
}
.removeMapButton button {
  font-size: 48px;
  color: black;
  height: 55px;
  width: 55px;
  border-radius: 100%;
  background: rgb(249 248 248);
  padding-bottom: 4px;
  border: solid 3px red;
}

.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #354093 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 8px 0 0 #354093, 0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px #354093 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}
.mainButton {
  border-radius: 50px;
  height: 56px;
  color: white !important;
  display: block;
  margin: 0px auto;
  height: 90px !important;
  width: 90px;
  border: solid darkgreen 2px;
}
.currentPosButton {
  position: absolute;
  top: 61px;
  font-size: 19px;
  z-index: 100;
  color: #0070b0;
  left: 11px;
  background: white;
  border: 3px solid #0070b0;
  padding: 6px 8px 6px;
  border-radius: 5px;
}
#erreurs {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 28px);
}
#evolution {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 29px);
}
#runningTime {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 13px);
}
#carte {
  /* font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 10px);
  color: #d41a1b; */
}

.center {
  display: block;
  margin: 0px auto;
}
#mapButton {
  width: 30px;
  position: absolute;
  top: 4px;
  right: -10px;
  margin-right: calc(50vw - 155px);
  font-size: 25px;
  color: white;
  /* border: solid 1px black; */
  width: 42px;
  height: 40px;
  padding: 5px;
  /* background: white; */
  z-index: 5;
}
#map {
  position: fixed;
  top: -100vh;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: hidden;
  transition: all 1s;
}
.showMap {
  position: fixed;
  top: 0 !important;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: hidden !important;
  transition: all 1s;
}

#imgUrl {
  display: block;
  margin: 0 auto;
}
.errorsTimeMap {
  /* background: rgba(255, 255, 255, 1); */
  border: none;
  font-size: 20px;
  display: flex;
  /* padding-top: 5px; */
  flex-direction: column;
  align-content: center;
  /* justify-content: center; */
  align-items: center;
}
.errorsTimeMap > svg {
  position: relative;
  bottom: 3px;
}
.pointNumber {
  margin: 0 auto;
  border: #3369bc solid 3px !important;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  font-size: 36px;
  padding-right: 1px;
  padding-top: 3px;
  font-weight: bold;
  color: white;
}
.rtl {
  direction: rtl;
}
.myModal {
  height: auto;
  min-height: 200px;
  min-width: 300px;
  width: auto;
  border-radius: 10px;
  max-width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
}
.modalInformationPoint {
  box-shadow: none;
  border: solid white 5px;
  width: 100vw;
  font-size: 22px;
  height: auto;
  overflow: auto;
  background: #4073da;
  font-weight: 100;
  color: white;
  height: 100vh;
}
.red {
  background-color: #d41a1b;
  border: solid white 10px;
}

.over {
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.continueButton {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
}
.question {
  text-align: center;
  font-family: "Assistant", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: black;
}
.userName {
  font-size: 18px;
  text-align: left;
  color: black;
}
.time {
  letter-spacing: 0.05em;
  font-size: 24px;
  font-family: "Cabin", sans-serif;
  text-align: center;
  /* color: rgb(14, 15, 14, 0.8); */
}
.startTime {
  letter-spacing: 0.05em;
  font-size: 40px;
  padding: 5px 0;
  font-family: "Inconsolata", monospace;
  color: #ffffff;
  text-align: center;
  position: relative;
  left: 50%;
  margin-top: 50px;
  top: -24px;
  transform: translate(-50%, -50%);
  color: #daf6ff;
}

.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.confetti {
  width: 15px;
  height: 15px;
  background-color: #b1f70f;
  position: absolute;
  top: 0px;
  left: 50%;
  animation: confetti 5s ease-in-out -2s infinite;
  transform-origin: left top;
  z-index: 5;
}
.confetti:nth-child(1) {
  background-color: #faf623;
  left: 10%;
  animation-delay: 0;
}
.confetti:nth-child(2) {
  background-color: #129dee;
  left: 20%;
  animation-delay: -5s;
}
.confetti:nth-child(3) {
  background-color: #e21f0d;
  left: 30%;
  animation-delay: -3s;
}
.confetti:nth-child(4) {
  background-color: #f1dc6f;
  left: 40%;
  animation-delay: -2.5s;
}
.confetti:nth-child(5) {
  background-color: #039cf5;
  left: 50%;
  animation-delay: -4s;
}
.confetti:nth-child(6) {
  background-color: #f52916;
  left: 60%;
  animation-delay: -6s;
}
.confetti:nth-child(7) {
  background-color: #5cf24e;
  left: 70%;
  animation-delay: -1.5s;
}
.confetti:nth-child(8) {
  background-color: #d717e9;
  left: 80%;
  animation-delay: -2s;
}
.confetti:nth-child(9) {
  background-color: #f30e34;
  left: 90%;
  animation-delay: -3.5s;
}
.confetti:nth-child(10) {
  background-color: #7cf24e;
  left: 100%;
  animation-delay: -2.5s;
}
.confetti:nth-child(11) {
  background-color: #f52916;
  left: 15%;
  animation-delay: -6.5s;
}
.confetti:nth-child(12) {
  background-color: #5cf24e;
  left: 25%;
  animation-delay: -1.8s;
}
.confetti:nth-child(13) {
  background-color: #d717e9;
  left: 75%;
  animation-delay: -2.5s;
}
.confetti:nth-child(14) {
  background-color: #f30e34;
  left: 85%;
  animation-delay: -3.8s;
}
.confetti:nth-child(15) {
  background-color: #7cf24e;
  left: 55%;
  animation-delay: -4.2s;
}
.confetti:nth-child(16) {
  background-color: #d717e9;
  left: 75%;
  animation-delay: -3.5s;
}
.confetti:nth-child(18) {
  background-color: #f30e34;
  left: 85%;
  animation-delay: -6.8s;
}
.confetti:nth-child(17) {
  background-color: #7cf24e;
  left: 55%;
  animation-delay: -4.9s;
}

@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }
  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(7vw, 60vh);
  }
  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-25vw, 80vh);
  }
  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(20vw, 110vh);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@font-face {
  font-family: noot;
  src: url(../../static/fonts/Noot/noot-aj.woff);
}
</style>
